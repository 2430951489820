import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderConfig,
  TcDataProviderType,
} from '@tc/abstract';
import { navigate, TcSmartDetailPopupComponent } from '@tc/advanced-components';
import { TcButtonsListComponent } from '@tc/buttons';
import {
  formlyColumn,
  formlyComponent,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcTranslateService,
} from '@tc/core';
import { Subscription } from 'rxjs';
import { ContactsGridComponent } from '../../modules/elevage/components/smart/contacts-grid/contacts-grid.component';
import { SitesGridComponent } from '../../modules/elevage/components/smart/sites-grid/sites-grid.component';
import { Elevage } from '../../modules/elevage/interfaces/elevage.interface';
import { getCurrentElevage } from '../../modules/elevage/store/elevage.selectors';
import { CustomRoutes } from '../../typings/custom-routes.enum';
import { DualChoice } from '../../typings/dual-choice.enum';
import { DocumentDomaine, DocumentType } from '../../typings/document.enum';
import { getTcSmartFormIsChangedState } from '@tc/smart-form';
import { CanDeactivateComponent } from '../../../modules/guards/deactivation-confirmation.guard';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';
import { saveElevage } from '../../modules/elevage/store/elevage.actions';
import { DEFAULT_TC_DATA_STATE_KEY, getTcData } from '@tc/data-store';
import { selectValueByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import { SuiviRepro } from '../../typings/suivi-repro.enum';
import { arraysEqual } from '@tc/core';
import { CustomConfigKeys } from '../../shared/interfaces/custom-config.interface';
import { ConfigService } from '../../../shared/services/config.service';

@Component({
  selector: 'app-elevage-detail',
  templateUrl: './elevage-detail.component.html',
  styleUrls: ['./elevage-detail.component.scss'],
})
export class ElevageDetailComponent
  extends TcSmartDetailPopupComponent
  implements OnDestroy, CanDeactivateComponent
{
  formStoreKey = 'elevage-grid';

  subscription = new Subscription();
  elevage: Elevage;

  dataStore$ = this.store$.pipe(
    select(DEFAULT_TC_DATA_STATE_KEY),
    filter(hasValue),
    distinctUntilChanged()
  );

  constructor(
    protected store$: Store<any>,
    public translate: TcTranslateService,
    private readonly config: ConfigService
  ) {
    super(store$, {});

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        const fixedElevage = {
          ...elevage,
          cuve: (elevage as any).cuve || 'Non',
          doubleactif: (elevage as any).doubleactif || 'Non',
          elevageBovin: (elevage as any).elevageBovin || 'Non',
          elevageCaprin: (elevage as any).elevageCaprin || 'Non',
          elevageOvin: (elevage as any).elevageOvin || 'Non',
          elevageAutres: (elevage as any).elevageAutres || 'Non',
          caprinAdherentCapgene:
            (elevage as any).caprinAdherentCapgene || 'Non',
          caprinAOP: (elevage as any).caprinAOP || 'Non',
          caprinBIO: (elevage as any).caprinBIO || 'Non',
          bovinBIO: (elevage as any).bovinBIO || 'Non',
          bovinIPE: (elevage as any).bovinIPE || 'Non',
          bovinRobot: (elevage as any).bovinRobot || 'Non',
        };
        this.elevage = fixedElevage;
        this.data = fixedElevage;
      });

    this.subscription.add(elevageSubscription);
  }

  async setFormConfig() {
    if (!this.elevage) return;

    this.dialogConfig.dialogStoreKey = this.formStoreKey;

    this.formConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey,
      autoSubmit: false,
      fieldConfigs: [],
      headerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        buttonsList: [
          {
            label: 'save',
            class: 'btn-primary btn-save-form',
            action: saveElevage,
            actionPayload: {
              elevageNumero: this.elevage.numero,
              storeKey: this.formStoreKey,
            },
          },
        ],
      },
      footerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        buttonsList: [
          {
            label: 'save',
            class: 'btn-primary btn-save-form',
            action: saveElevage,
            actionPayload: {
              elevageNumero: this.elevage.numero,
              storeKey: this.formStoreKey,
            },
          },
        ],
      },
    };

    this.dialogConfig.headerConfig.enableActionsSlot = false;

    this.dialogConfig.footerConfig.enableActionsSlot = false;

    this.initFields();
  }

  initFields() {
    this.formConfig.fieldConfigs = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyColumn({
                wrappers: ['panel'],
                templateOptions: {
                  label: this.translate.instant('elevage-synthesis'),
                },
                fields: [
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'numero',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'contactPrincipal',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'adresse',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'telephone',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'portable1',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'complementAdresse',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'email',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'codePostal',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'ville',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'territoire.groupe.secteur.libelle',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'contratCrea',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'contratBovinViande',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'reglement.intitule',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'contratBovinLait',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'contratCaprin',
                        type: TcFormlyComponent.TcSmartSelect,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          valueFieldName: 'intitule',
                          labelFieldName: 'intitule',
                          dataProvider: {
                            configType: TcConfigTypes.TcDataProvider,
                            providerType: TcDataProviderType.BreezeJs,
                            dataSet: 'refContratCaprin',
                            fields: 'intitule',
                          },
                          disabled: this.elevage.contratCaprin ? true : false,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                ],
                xlColSpan: 12,
                xxlColSpan: 7,
              }),
              formlyColumn({
                wrappers: ['panel'],
                templateOptions: {
                  label: this.translate.instant('financial-synthesis'),
                },
                fields: [
                  formlyRow({
                    fields: [
                      formlyColumn({
                        fields: [
                          formlyColumn({
                            fields: [
                              formlyRow({
                                fields: [
                                  formlyControl({
                                    key: 'syntheseFinanciere.caN',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      type: 'currency',
                                      currencyCode: 'EUR',
                                      digitsInfo: '1.3-3',
                                      locale: 'fr-FR',
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                  formlyControl({
                                    key: 'syntheseFinanciere.caN1',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      type: 'currency',
                                      currencyCode: 'EUR',
                                      digitsInfo: '1.3-3',
                                      locale: 'fr-FR',
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                ],
                              }),
                              formlyRow({
                                fields: [
                                  formlyControl({
                                    key: 'syntheseFinanciere.ca12M',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      type: 'currency',
                                      currencyCode: 'EUR',
                                      digitsInfo: '1.3-3',
                                      locale: 'fr-FR',
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                  formlyControl({
                                    key: 'syntheseFinanciere.encoursClient',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                ],
                              }),
                              formlyRow({
                                fields: [
                                  formlyControl({
                                    key: 'syntheseFinanciere.commandesEnCoursLabel',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                  formlyControl({
                                    key: 'syntheseFinanciere.devisEnCoursLabel',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                ],
                              }),
                              formlyRow({
                                fields: [
                                  formlyControl({
                                    key: 'syntheseFinanciere.soldeComptableN',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      type: 'currency',
                                      currencyCode: 'EUR',
                                      digitsInfo: '1.3-3',
                                      locale: 'fr-FR',
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                  formlyControl({
                                    key: 'syntheseFinanciere.impayes',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                ],
                              }),
                              formlyRow({
                                fields: [
                                  formlyControl({
                                    key: 'syntheseFinanciere.impayesNbdemoisCA',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                  formlyControl({
                                    key: 'syntheseFinanciere.ratioimpaye',
                                    type: TcFormlyComponent.FormlyInput,
                                    colSpan: 6,
                                    templateOptions: {
                                      type: 'number',
                                      appearance: 'outline',
                                      disabled: true,
                                    },
                                    modelOptions: {
                                      updateOn: 'blur',
                                    },
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                        colSpan: 8,
                      }),
                      formlyColumn({
                        fields: [
                          formlyComponent({
                            component: TcButtonsListComponent,
                            className: 'financial-synthesis-btn-list',
                            componentData: {
                              buttonsList: [
                                {
                                  label: 'invoices',
                                  class: this.isInvoicesBtnDisabled()
                                    ? 'btn-disabled'
                                    : '',
                                  action: navigate,
                                  actionPayload: {
                                    route: `/${CustomRoutes.FactureList}`,
                                  },
                                },
                                {
                                  label: 'orders',
                                  class: this.isOrdersBtnDisabled()
                                    ? 'btn-disabled'
                                    : '',
                                  action: navigate,
                                  actionPayload: {
                                    route: `/${CustomRoutes.CommandesList}`,
                                  },
                                },
                                {
                                  label: 'quotation',
                                  class: this.isQuotationsBtnDisabled()
                                    ? 'btn-disabled'
                                    : '',
                                  action: navigate,
                                  actionPayload: {
                                    route: `/${CustomRoutes.DevisList}`,
                                  },
                                },
                                {
                                  label: 'unpaid',
                                  action: navigate,
                                  actionPayload: {
                                    route: `/${CustomRoutes.FactureList}`,
                                    queryParams: {
                                      impaye: true,
                                    },
                                  },
                                },
                                {
                                  label: 'xroptima-purchase-history',
                                  class: this.isXrOptimaHistoryBtnDisabled()
                                    ? 'btn-disabled'
                                    : '',
                                  action: navigate,
                                  actionPayload: {
                                    route: `/${CustomRoutes.XrOptimaPurchaseHistory}`,
                                  },
                                },
                              ],
                            },
                          }),
                        ],
                        colSpan: 4,
                      }),
                    ],
                  }),
                ],
                xlColSpan: 12,
                xxlColSpan: 5,
              }),
            ],
          }),
          formlyRow({
            fields: [
              formlyColumn({
                wrappers: ['panel'],
                templateOptions: {
                  label: this.translate.instant('actions'),
                },
                fields: [
                  formlyComponent({
                    className: 'tc-buttons-card-list',
                    component: TcButtonsListComponent,
                    componentData: {
                      buttonsList: [
                        {
                          label: 'make-delivery',
                          ionIcon: 'document-text-outline',
                          class: 'tc-btn-card-pink',
                          action: navigate,
                          actionPayload: {
                            route: `/${CustomRoutes.Livraison}`,
                          },
                        },
                        {
                          label: 'make-quote-take-order',
                          ionIcon: 'document-text-outline',
                          class: 'tc-btn-card-blue',
                          action: navigate,
                          actionPayload: {
                            route: `/${CustomRoutes.MakeEstimateOrTakeOrder}`,
                          },
                        },

                        ...(this.config.get(
                          CustomConfigKeys.enableRemiseEnBanque
                        )
                          ? [
                              {
                                label: 'make-cash-collection',
                                ionIcon: 'wallet-outline',
                                class: 'tc-btn-card-yellow',
                                action: navigate,
                                actionPayload: {
                                  route: `/${CustomRoutes.FactureList}`,
                                  queryParams: {
                                    impaye: true,
                                  },
                                },
                              },
                            ]
                          : []),
                      ],
                    },
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      formlyColumn({
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: { label: this.translate.instant('detail-elevage') },
        fields: [
          formlyRow({
            wrappers: ['panel'],
            templateOptions: {
              label: this.translate.instant('general-informations'),
            },
            fields: [
              formlyColumn({
                colSpan: 6,
                fields: [
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'nom',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'numeroTVAIntracommunautaire',
                        colSpan: 3,
                        type: TcFormlyComponent.FormlyInput,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'nombreAssocies',
                        colSpan: 3,
                        type: TcFormlyComponent.FormlyInput,
                        templateOptions: {
                          appearance: 'outline',
                          type: 'number',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'doubleactif',
                        type: TcFormlyComponent.TcToggle,
                        colSpan: 6,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'portable2',
                        colSpan: 3,
                        type: TcFormlyComponent.FormlyInput,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'cuve',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'pays',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'siret',
                        colSpan: 3,
                        type: TcFormlyComponent.FormlyInput,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'elevageBovin',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'elevageCaprin',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'territoire.groupe.secteur.numero',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'codeAPE',
                        colSpan: 3,
                        type: TcFormlyComponent.FormlyInput,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'elevageOvin',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'elevageAutres',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'adherent',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'editionFacturePapier',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'engagedansautresstructures',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                ],
              }),
              formlyColumn({
                colSpan: 6,
                className: 'textarea-display',
                fields: [
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'geographie',
                        type: TcFormlyComponent.FormlyTextarea,
                        colSpan: 12,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: true,
                          rows: 5,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'coordonneesGPS',
                        type: TcFormlyComponent.FormlyTextarea,
                        colSpan: 12,
                        templateOptions: {
                          appearance: 'outline',
                          rows: 5,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'prestationParDefault',
                        colSpan: 6,
                        type: TcFormlyComponent.TcSmartSelect,
                        templateOptions: {
                          appearance: 'outline',
                          valueFieldName: 'intitule',
                          labelFieldName: 'intitule',
                          dataProvider: {
                            configType: TcConfigTypes.TcDataProvider,
                            providerType: TcDataProviderType.BreezeJs,
                            dataSet: 'refPrestationParDefault',
                            fields: 'intitule',
                          },
                          disabled: true,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          formlyRow({
            fields: [
              formlyColumn({
                wrappers: ['panel'],
                templateOptions: {
                  label: this.translate.instant(
                    'elevage-sheet-bovin-data-supp'
                  ),
                },
                colSpan: 6,
                fields: [
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'bovinNbvaches',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          type: 'number',
                          disabled: this.elevage.bovinNbvaches ? true : false,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinProductionParVacheParAn',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          type: 'number',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinBIO',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinIPE',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'bovinControleDePerf',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinDeboucheslait',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinDebouchesviandes',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'bovinRace1',
                        colSpan: 2,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: this.elevage.bovinRace1 ? true : false,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinRace2',
                        colSpan: 2,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: this.elevage.bovinRace2 ? true : false,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinRace3',
                        colSpan: 2,
                        templateOptions: {
                          appearance: 'outline',
                          disabled: this.elevage.bovinRace3 ? true : false,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinTypederation',
                        type: TcFormlyComponent.TcAutoComplete,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          dataProvider: this.getBovinTypeDeRationDataProvider(),
                          allowNewItem: true,
                          updateOnBlur: true,
                          autocompleteMinLength: 1,
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'bovinNomsDesVendeursDeDose',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinSuivireproQui',
                        colSpan: 3,
                        defaultValue: SuiviRepro.NA,
                        type: TcFormlyComponent.FormlySelect,
                        templateOptions: {
                          appearance: 'outline',
                          options: [
                            {
                              value: SuiviRepro.NA,
                              label: this.translate.instant('suivi-repro.NA'),
                            },
                            {
                              value: SuiviRepro.CEL,
                              label: this.translate.instant('suivi-repro.CEL'),
                            },
                            {
                              value: SuiviRepro.INDEPENDANT,
                              label: this.translate.instant(
                                'suivi-repro.INDEPENDANT'
                              ),
                            },
                            {
                              value: SuiviRepro.VETERINAIRE,
                              label: this.translate.instant(
                                'suivi-repro.VETERINAIRE'
                              ),
                            },
                            {
                              value: SuiviRepro.XRRERPO,
                              label: this.translate.instant(
                                'suivi-repro.XRRERPO'
                              ),
                            },
                          ],
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinRobot',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'bovinMontenaturelle',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'bovinPourcentageAchatdeDosesExt',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'bovinNomduveterinaire',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                ],
              }),
              formlyColumn({
                wrappers: ['panel'],
                templateOptions: {
                  label: this.translate.instant(
                    'elevage-sheet-caprin-data-supp'
                  ),
                },
                colSpan: 6,
                fields: [
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'caprinAdherentCapgene',
                        colSpan: 6,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinAOP',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinBIO',
                        colSpan: 3,
                        type: TcFormlyComponent.TcToggle,
                        templateOptions: {
                          checkedValue: DualChoice.Yes,
                          unCheckedValue: DualChoice.No,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'caprinNbChevres',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          type: 'number',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'carpinNbChevrettes',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          type: 'number',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinModederepro',
                        type: TcFormlyComponent.TcAutoComplete,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          dataProvider: this.getCaprinModedeReproDataProvider(),
                          allowNewItem: true,
                          updateOnBlur: true,
                          autocompleteMinLength: 1,
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'caprinNbBoucs',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                          type: 'number',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinControledeperf',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinDeclenchementchaleurs',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'caprinRace1',
                        colSpan: 2,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinRace2',
                        colSpan: 2,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinRace3',
                        colSpan: 2,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinDeboucheslait',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                      formlyControl({
                        key: 'caprinDebouchesviandes',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 3,
                        templateOptions: {
                          appearance: 'outline',
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'caprinProductionParChevreParAn',
                        type: TcFormlyComponent.FormlyInput,
                        colSpan: 6,
                        templateOptions: {
                          appearance: 'outline',
                          rows: 2,
                        },
                        modelOptions: {
                          updateOn: 'blur',
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          formlyRow(this.getContactGridComponent()),
          formlyRow(this.getSiteGridComponent()),
        ],
      }),
    ];
  }

  getContactGridComponent() {
    return {
      wrappers: ['panel'],
      className: 'grid',
      templateOptions: {
        label: this.translate.instant('contacts-grid.title'),
      },
      fields: [
        formlyComponent({
          component: ContactsGridComponent,
          className: 'elevage-contact-grid',
          componentData: {
            contacts: this.elevage.contact,
          },
        }),
      ],
    };
  }

  getSiteGridComponent() {
    return {
      wrappers: ['panel'],
      className: 'grid',
      templateOptions: {
        label: this.translate.instant('sites-grid.title'),
      },
      fields: [
        formlyComponent({
          component: SitesGridComponent,
          className: 'elevage-site-grid',
          componentData: {
            sites: this.elevage.site,
          },
        }),
      ],
    };
  }

  getBovinTypeDeRationDataProvider() {
    const dataProvider: TcDataProviderConfig = {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Elevage',
      fields: 'bovinTypederation',
      distinct: true,
      transformFn: (result) => {
        const newResult = result.map((item) => item.bovinTypederation);
        return newResult;
      },
    };

    return dataProvider;
  }

  getCaprinModedeReproDataProvider() {
    const dataProvider: TcDataProviderConfig = {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Elevage',
      fields: 'caprinModederepro',
      distinct: true,
      transformFn: (result) => {
        const newResult = result.map((item) => item.caprinModederepro);
        return newResult;
      },
    };

    return dataProvider;
  }

  isInvoicesBtnDisabled() {
    const currentDate = new Date();
    const currentDateMinus12Months = new Date(
      currentDate.getUTCFullYear() - 1,
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    );

    const hasInvoiceWithin12Months =
      this.elevage.syntheseFinanciere?.factures?.some((facture) => {
        const condition = new Date(facture.date) >= currentDateMinus12Months;
        return condition;
      });

    const hasInvoiceDocument = this.elevage.documents?.some((doc) => {
      const condition =
        (doc.type === DocumentType.VenteFacture ||
          doc.type === DocumentType.VenteFactureCompta) &&
        new Date(doc.date) >= currentDateMinus12Months;
      return condition;
    });

    const disabled = !hasInvoiceWithin12Months || !hasInvoiceDocument;
    return disabled;
  }

  isOrdersBtnDisabled() {
    const isDisabled =
      this.elevage.documents.findIndex(
        (doc) => doc.type === DocumentType.VenteBC
      ) < 0;
    return isDisabled;
  }

  isQuotationsBtnDisabled() {
    const isDisabled =
      this.elevage.documents.findIndex(
        (doc) => doc.type === DocumentType.Devis
      ) < 0;
    return isDisabled;
  }

  isXrOptimaHistoryBtnDisabled() {
    let currentDateMinus18Months = new Date();
    currentDateMinus18Months.setMonth(currentDateMinus18Months.getMonth() - 18);
    currentDateMinus18Months = new Date(
      currentDateMinus18Months.getUTCFullYear(),
      currentDateMinus18Months.getUTCMonth(),
      currentDateMinus18Months.getUTCDate()
    );

    const isDisabled = !this.elevage.documents.some(
      (doc) =>
        doc.domaine === DocumentDomaine.Vente &&
        (doc.type === DocumentType.VenteBL ||
          doc.type === DocumentType.VenteFacture ||
          doc.type === DocumentType.VenteFactureCompta) &&
        new Date(doc.date) >= currentDateMinus18Months
    );

    return isDisabled;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.formSubmitModelSubscription?.unsubscribe();
  }

  async shouldConfirmDeactivation(): Promise<boolean> {
    const sitesData = await selectValueByKey(
      getTcData,
      this.dataStore$,
      'sites-grid'
    );

    const contactsData = await selectValueByKey(
      getTcData,
      this.dataStore$,
      'contacts-grid'
    );

    const isSitesChanged = !arraysEqual(
      this.elevage?.site,
      sitesData.map((item) => R.omit(['_id'], item))
    );

    const isContactsChanged = !arraysEqual(
      this.elevage?.contact,
      contactsData.map((item) => R.omit(['_id'], item))
    );

    const isElevageChanged = await this.store$
      .select(getTcSmartFormIsChangedState(this.formStoreKey))
      .pipe(first())
      .toPromise();

    return (
      this.elevage && (isElevageChanged || isContactsChanged || isSitesChanged)
    );
  }
}
