import { LayoutComponent } from './../modules/layout/components/smart/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../modules/guards/auth.guard';
import { RouteResolver } from '../shared/resolvers/route.resolvers';
import { PermissionAction } from './typings/permission-action.enum';
import { PermissionSubject } from './typings/permission-subject.enum';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { CustomRoutes } from './typings/custom-routes.enum';
import { HomeComponent } from './pages/home-page/home.component';
import { EtlPageComponent } from './pages/etl-page/etl-page.component';
import { EtlXpert2SagePageComponent } from './pages/etl-xpert2sage-page/etl-xpert2sage-page.component';
import { CustomLayoutComponent } from './modules/layout/components/smart/custom-layout/custom-layout.component';
import { LoginLayoutComponent } from './modules/layout/components/dumb/login-layout/login-layout.component';
import { CustomLoginComponent } from './pages/login-page/custom-login.component';
import { GenericRoutes } from '../shared/typings/generic-routes';
import { OfflineModeResolver } from './resolvers/offline-mode.resolvers';
import { ElevagePageComponent } from './pages/elevage-page/elevage-page.component';
import { ElevageDetailComponent } from './pages/elevage-detail/elevage-detail.component';
import { DevisPageComponent } from './pages/devis-page/devis-page.component';
import { CommandesPageComponent } from './pages/commandes-page/commandes-page.component';
import { FacturesPageComponent } from './pages/factures-page/factures-page.component';
import { ElevageSelectedResolver } from './resolvers/elevage-selected.resolvers';
import { CommandeDetailPageComponent } from './pages/commande-detail-page/commande-detail-page.component';
import { LivraisonDetailPageComponent } from './pages/livraison-detail-page/livraison-detail-page.component';
import { DevisDetailPageComponent } from './pages/devis-detail/devis-detail.component';
import { XpertPageComponent } from './pages/xpert-page/xpert-page.component';
import { XrOptimaPurchaseHistoryPageComponent } from './pages/xroptima-purchase-history/xroptima-purchase-history.component';
import { CatalogArticlesComponent } from './pages/catalog-articles/catalog-articles.component';
import { OfflineModeGuard } from '../modules/guards/offline-mode.guard';
import { ConsultationStockComponent } from './pages/consultation-stock/consultation-stock.component';
import { BankDepositPageComponent } from './pages/bank-deposit-page/bank-deposit-page.component';
import { BankDepositPageGuard } from './pages/bank-deposit-page/bank-deposit-page.guard';
import { LivraisonPageComponent } from './pages/livraison-page/livraison-page.component';
import { DeactivationConfirmationGuard } from '../modules/guards/deactivation-confirmation.guard';
import { XpertDepositPageComponent } from './pages/xpert-deposit-page/xpert-deposit-page.component';
import { XpertTransfertPageComponent } from './pages/xpert-transfert-page/xpert-transfert-page.component';
import { EtlXpertExtractOnlyPageComponent } from './pages/etl-xpert-extract-only-page/etl-xpert-extract-only-page.component';
import { DosesPageComponent } from './pages/doses-page/doses-page.component';
import { RestockPageComponent } from './pages/restock-page/restock-page.component';
import { RestockDetailPageComponent } from './pages/restock-detail-page/restock-detail-page.component';

const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, OfflineModeGuard],
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      {
        path: CustomRoutes.ElevageDetail,
        component: ElevageDetailComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
        canDeactivate: [DeactivationConfirmationGuard],
      },
      {
        path: CustomRoutes.DevisList,
        component: DevisPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
      },
      {
        path: CustomRoutes.CommandesList,
        component: CommandesPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
      },
      {
        path: CustomRoutes.FactureList,
        component: FacturesPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
      },
      {
        path: CustomRoutes.DetailCommandesList,
        component: CommandeDetailPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
        canDeactivate: [DeactivationConfirmationGuard],
      },
      {
        path: CustomRoutes.DeliveriesList,
        component: LivraisonDetailPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
        canDeactivate: [DeactivationConfirmationGuard],
      },
      {
        path: CustomRoutes.DevisDetail,
        component: DevisDetailPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
        canDeactivate: [DeactivationConfirmationGuard],
      },
      {
        path: CustomRoutes.XrOptimaPurchaseHistory,
        component: XrOptimaPurchaseHistoryPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
      },
      {
        path: CustomRoutes.XpertList,
        component: XpertPageComponent,
        pathMatch: 'full',
        data: {
          permissions: [
            {
              action: PermissionAction.List,
              subject: PermissionSubject.Collaborateur,
            },
          ],
        },
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.BankDeposit,
        component: BankDepositPageComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
        canActivate: [BankDepositPageGuard],
      },
      {
        path: CustomRoutes.CatalogArticles,
        component: CatalogArticlesComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.Doses,
        component: DosesPageComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.ConsultationStock,
        component: ConsultationStockComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.Livraison,
        component: LivraisonPageComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
      },
      {
        path: CustomRoutes.MakeEstimateOrTakeOrder,
        component: CatalogArticlesComponent,
        pathMatch: 'full',
        resolve: {
          elevageSelected: ElevageSelectedResolver,
          route: RouteResolver,
        },
      },
      {
        path: CustomRoutes.XpertDeposit,
        component: XpertDepositPageComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.XpertTransfert,
        component: XpertTransfertPageComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.Restock,
        component: RestockPageComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
      {
        path: CustomRoutes.RestockDetail,
        component: RestockDetailPageComponent,
        pathMatch: 'full',
        resolve: { route: RouteResolver },
      },
    ],
    resolve: {
      route: RouteResolver,
      offline: OfflineModeResolver,
    },
    data: {
      // Same permissions must be applied here that the route of the dashboard component.
      // We need to check the connected user has the rights to consult the default landing page.
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.Dashboard,
          subject: PermissionSubject.All,
        },
      ],
    },
  },
  {
    path: GenericRoutes.Login,
    component: LoginLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CustomLoginComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
  {
    // Default permission failure landing page. Path must be the same as config key defaultForbiddenRoute.
    // We are not in a class, we cannot use configService to load the value.
    path: CustomRoutes.AccesNonAutorise,
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ForbiddenPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
  {
    path: CustomRoutes.ElevageList,
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, OfflineModeGuard],
    children: [
      { path: '', component: ElevagePageComponent, pathMatch: 'full' },
    ],
    resolve: {
      route: RouteResolver,
      offline: OfflineModeResolver,
    },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.List,
          subject: PermissionSubject.Elevage,
        },
      ],
    },
  },
  {
    path: CustomRoutes.Etl,
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, OfflineModeGuard],
    children: [{ path: '', component: EtlPageComponent, pathMatch: 'full' }],
    resolve: {
      route: RouteResolver,
      offline: OfflineModeResolver,
    },
  },
  {
    path: CustomRoutes.EtlXpert2Sage,
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, OfflineModeGuard],
    children: [
      { path: '', component: EtlXpert2SagePageComponent, pathMatch: 'full' },
    ],
    resolve: {
      route: RouteResolver,
      offline: OfflineModeResolver,
    },
  },
  {
    path: CustomRoutes.EtlXpertExtractOnly,
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, OfflineModeGuard],
    children: [
      {
        path: '',
        component: EtlXpertExtractOnlyPageComponent,
        pathMatch: 'full',
      },
    ],
    resolve: {
      route: RouteResolver,
      offline: OfflineModeResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomRoutingModule {}
