import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  FilterTypesEnum,
  TcSmartFilterConfig,
  TcFilterItem,
  TcFilterDef,
  ITcDataService,
  MaterialColor,
} from '@tc/abstract';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  formlyColumn,
  formlyComponent,
  formlyControl,
  formlyRow,
  getTcFilters,
  getTcGridSelection,
  NgRxTcFilterState,
  NgRxTcGridState,
  setTcGridSelection,
  TcFormlyComponent,
  TcGridCellComponent,
  TcGridLoadedDataEvent,
  TcGridReadyEvent,
  TcTranslateService,
  updateTcFilterSuccess,
} from '@tc/core';
import {
  TcSmartGridComponent,
  editTcGridButtonClicked,
} from '@tc/advanced-components';
import { Observable, Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  MaterialButtonType,
  TcButtonsListComponent,
  TcSmartButton,
} from '@tc/buttons';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { XpertService } from '../../../../../services/xpert.service';
import {
  createEstimateFromCatalog,
  createOrderFromCatalog,
  setCanMakeEstimate,
  setCanMakeOrder,
  updateCatalogGridColumns,
} from '../../../store/catalog-article.actions';
import { selectByKey, selectValueByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Article } from '../../../interfaces/article.interface';
import { DualChoiceNumeric } from '../../../../../typings/dual-choice.enum';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormCurrentModel,
  NgRxTcSmartFormState,
  setTcSmartFormModel,
} from '@tc/smart-form';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { DAS } from '../../../../../typings/DAS.enum';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
  getEstimateBtnDisabledState,
  getOrderBtnDisabledState,
} from '../../../store/catalog-article.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../custom/typings/document.enum';
import { TcDataService } from '@tc/data-store';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { GridApi } from 'ag-grid-community';
import { PriceService } from '../../../../../services/price.service';
import { decimalsForXpertMobile } from '../../../../../shared/decimals';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { ArticleOrigin } from '../../../../commande/store/commande.payloads';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { Actions, ofType } from '@ngrx/effects';
import { ArticleService } from '../../../../../services/article.service';
import { GammeFamily } from 'apps/frontend/src/app/custom/typings/gamme.enum';

@Component({
  selector: 'app-catalog-article-grid',
  templateUrl: './catalog-article-grid.component.html',
  styleUrls: ['./catalog-article-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CatalogArticleGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  private gridApi: GridApi;
  private xpert: any;

  storeKey = 'catalog-article-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcFilterState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  buttonsList: TcSmartButton[];
  elevage: Elevage;
  commandeDetailLines: DocumentLigne[];
  subscription = new Subscription();
  documentInEdition: ElevageDocument = null;

  DASFilterFromSelection: string = null;
  devisDocNum: string = null;
  orderDocNum: string = null;

  isCatalogArticlePage = this.router.url === '/' + CustomRoutes.CatalogArticles;
  defaultArticleFilters: TcFilterItem[] = [];

  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'DAS',
    });
  private readonly elevageDataService: ITcDataService<any> =
    this.dataService.getService('Elevage', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Elevage',
    });

  constructor(
    store$: Store<any>,
    private dataService: TcDataService,
    private readonly translate: TcTranslateService,
    private readonly xpertService: XpertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly priceService: PriceService,
    private readonly actions$: Actions,
    private router: Router,
    private readonly articleService: ArticleService
  ) {
    super(store$);

    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);
  }

  /**
   * Load prices for all articles in the grid
   */
  public async loadPrices() {
    if (this.gridApi) {
      let elevage = null;
      if (this.elevage) {
        elevage = this.elevage;
      } else {
        // Load default elevage. Chosen by MTU in the database.
        const elevageResult = await this.elevageDataService.getData(0, null, {
          filters: [
            {
              key: 'numero',
              value: '999999999',
              filterType: FilterTypesEnum.Equal,
            },
          ],
        });
        elevage = elevageResult.data[0];
      }

      // We iterate on the nodes first, as this method is creating a sub method and if you set async inside, it will recreate priceService at each call.
      // Very bad for performances, as the service is querying a lot of data.
      const nodes: any[] = [];
      this.gridApi.forEachNode((node) => {
        nodes.push(node);
      });

      for (const node of nodes) {
        const article = node.data;
        let document = this.priceService.createMockDocument(
          article,
          elevage,
          this.xpert
        );
        document = await this.priceService.calculatePrices(document, elevage);

        const columnName = 'prixHT';
        const newValue = document?.ligne[0]?.prixUnitaire ?? 0;

        node.setData({ ...article, [columnName]: newValue });
      }
    }
  }

  public async onReady(event: TcGridReadyEvent) {
    this.gridApi = event.api;
  }

  public async onLoadedData(event: TcGridLoadedDataEvent) {
    if (event?.data.length > 0) {
      await this.loadPrices();
    }
  }

  async ngOnInit() {
    this.xpert = await this.xpertService.getConnectedXpert();
    this.devisDocNum = this.activatedRoute.snapshot.queryParams['devisDocNum'];
    this.orderDocNum = this.activatedRoute.snapshot.queryParams['orderDocNum'];

    const documentInEditionSubscription = this.store$
      .pipe(select(getCurrentDocument))
      .subscribe(async (data) => {
        this.documentInEdition = data;
        if (
          this.documentInEdition !== null &&
          this.documentInEdition.ligne.length > 0
        ) {
          // Load the first article to get the DAS from it
          const articles = await this.articleDataService.getData(0, null, {
            filters: [
              {
                key: 'reference',
                value: this.documentInEdition.ligne[0].article.reference,
                filterType: FilterTypesEnum.Equal,
              },
            ],
          });
          const articleFound = articles?.data[0];
          // If you found the DAS, preselect it
          if (articleFound !== undefined) {
            const currentFilter: TcFilterDef = await selectValueByKey(
              getTcFilters,
              this.filterStore$,
              this.storeKey
            );

            const currentFilterModel = await selectValueByKey(
              getTcSmartFormCurrentModel,
              this.formStore$,
              `${this.storeKey}.filter`
            );

            if (
              !currentFilter?.filters ||
              !currentFilter.filters.some(
                (filterControl) =>
                  filterControl.key === 'DAS.intitule' &&
                  filterControl.value === articleFound?.DAS?.intitule
              )
            ) {
              const newModel = {
                ...currentFilterModel,
                DAS: {
                  ...currentFilterModel?.DAS,
                  intitule: articleFound?.DAS?.intitule,
                },
              };

              this.store$.dispatch(
                setTcSmartFormModel({
                  storeKey: `${this.storeKey}.filter`,
                  model: newModel,
                })
              );
            }
          }
        }
      });

    this.subscription.add(documentInEditionSubscription);

    const articleFilters: TcFilterItem[] = [
      {
        key: 'enSommeil',
        value: DualChoiceNumeric.No.toString(),
        filterType: FilterTypesEnum.Equal,
      },
    ];

    if (!this.isCatalogArticlePage) {
      articleFilters.push({
        key: 'DAS.intitule',
        value: DAS.XROptima,
        filterType: FilterTypesEnum.Equal,
      });

      articleFilters.push({
        key: 'DAS.offre.gamme.intitule',
        value: GammeFamily.Remise,
        filterType: FilterTypesEnum.NotEqual,
      });

      articleFilters.push({
        key: 'DAS.offre.gamme.intitule',
        value: GammeFamily.Nutrition,
        filterType: FilterTypesEnum.NotEqual,
      });
    }

    const collaboratorsFilters =
      await this.xpertService.getConnectedXpertArticlesFilters();
    for (const collaboratorFilter of collaboratorsFilters) {
      articleFilters.push(collaboratorFilter);
    }

    this.defaultArticleFilters = articleFilters;

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      gridOptions: {},
      columnNumberPerDevice: {
        extraSmallDevice: 14,
        smallDevice: 14,
        mediumDevice: 14,
        largeDevice: 14,
        extraLargeDevice: 14,
        extraExtraLargeDevice: 14,
      },
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'Article',
        fields:
          'reference,intitule,conditionnementXpert,sousFamille,DAS,nombreUGV,typeContrat,typeRemise,commissions,enSommeil',
        filter: {
          filters: articleFilters,
        },
      },
      columns: [
        {
          field: 'reference',
          minWidth: 140,
          maxWidth: 140,
          headerCheckboxSelection: this.elevage ? true : false,
          checkboxSelection: this.elevage ? true : false,
          comparator: gridComparatorForString,
        },
        {
          field: 'intitule',
          flex: 1,
          cellRenderer: (params) =>
            `<span title="${params?.data?.intitule}">${params?.data?.intitule}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: 'conditionnementXpert',
          width: 80,
          maxWidth: 80,
          comparator: gridComparatorForString,
        },
        {
          field: 'sousFamille',
          maxWidth: 100,
          comparator: gridComparatorForString,
        },
        {
          field: 'DAS.offre.gamme.famille.intitule',
          maxWidth: 100,
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.DAS?.offre?.gamme?.famille?.intitule
              ? valueA?.DAS?.offre?.gamme?.famille?.intitule
              : '';
            const valueBCleaned = valueB?.DAS?.offre?.gamme?.famille?.intitule
              ? valueB?.DAS?.offre?.gamme?.famille?.intitule
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'DAS.offre.gamme.intitule',
          maxWidth: 150,
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.DAS?.offre?.gamme?.intitule
              ? valueA?.DAS?.offre?.gamme?.intitule
              : '';
            const valueBCleaned = valueB?.DAS?.offre?.gamme?.intitule
              ? valueB?.DAS?.offre?.gamme?.intitule
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'DAS.offre.intitule',
          maxWidth: 150,
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.DAS?.offre?.intitule
              ? valueA?.DAS?.offre?.intitule
              : '';
            const valueBCleaned = valueB?.DAS?.offre?.intitule
              ? valueB?.DAS?.offre?.intitule
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        ...(this.isCatalogArticlePage
          ? [
              {
                field: 'DAS.intitule',
                maxWidth: 100,
              },
            ]
          : []),
        {
          field: 'poidsBrut',
          minWidth: 70,
          maxWidth: 80,
          cellClass: ' text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.poidsBrut
                ? `${params.data.poidsBrut.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : 0
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'nombreUGV',
          minWidth: 60,
          maxWidth: 80,
          cellClass: ' text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.nombreUGV
                ? `${params.data.nombreUGV.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : ''
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'typeContrat',
          maxWidth: 110,
          comparator: gridComparatorForString,
        },
        {
          field: 'prixHT',
          sortable: false, //Property not existing in database.
          maxWidth: 100,
          cellClass: ' text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixHT
                ? `${params.data.prixHT.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : ''
            }`,
        },
        {
          field: 'typeRemise',
          minWidth: 60,
          maxWidth: 100,
          valueFormatter: (params) =>
            `${
              params.data.typeRemise !== null &&
              params.data.typeRemise !== undefined
                ? params.data.typeRemise
                : ''
            }`,
          comparator: gridComparatorForString,
        },
        {
          field: 'actions',
          sortable: false,
          minWidth: 100,
          maxWidth: 100,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          valueGetter: (params) => [' '],
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip-promotion',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'PromotionPopupComponent',
                  width: '29.75em',
                  height: 'auto',
                },
                name: 'promotions-popup',
              },
            ],
          },
        },
      ],
      filterConfig: this.getFilterConfig(),
    };

    super.ngOnInit();

    this.watchSelection();
    if (this.isCatalogArticlePage) this.watchFilterModel();

    this.subscription.add(
      this.actions$.pipe(ofType(updateTcFilterSuccess)).subscribe((data) => {
        this.articleService.applyDASSubFilters(
          this.storeKey,
          null,
          this.defaultArticleFilters
        );
      })
    );

    this.articleService.applyDASSubFilters(
      this.storeKey,
      null,
      this.defaultArticleFilters
    );
  }

  watchSelection() {
    const selectedRowsSubscription = selectByKey(
      getTcGridSelection,
      this.gridStore$,
      this.storeKey
    ).subscribe(async (selectedRows: Article[]) => {
      if (this.isCatalogArticlePage) {
        const selectedDASList = selectedRows
          .map((article) => article.DAS.intitule)
          .filter(
            (articleDAS, index, self) => self.indexOf(articleDAS) === index
          );

        const currentFilterModel = await selectValueByKey(
          getTcSmartFormCurrentModel,
          this.formStore$,
          `${this.storeKey}.filter`
        );

        if (selectedRows.length === 0 && this.DASFilterFromSelection) {
          const newModel = {
            ...currentFilterModel,
            DAS: {
              ...currentFilterModel?.DAS,
              intitule: undefined,
            },
          };
          this.store$.dispatch(
            setTcSmartFormModel({
              storeKey: `${this.storeKey}.filter`,
              model: newModel,
            })
          );

          this.DASFilterFromSelection = null;
        } else if (selectedDASList.length === 1) {
          const currentFilter: TcFilterDef = await selectValueByKey(
            getTcFilters,
            this.filterStore$,
            this.storeKey
          );

          if (
            !currentFilter.filters.some(
              (filterControl) =>
                filterControl.key === 'DAS.intitule' &&
                filterControl.value === selectedRows[0].DAS?.intitule
            )
          ) {
            const newModel = {
              ...currentFilterModel,
              DAS: {
                ...currentFilterModel?.DAS,
                intitule: selectedRows[0].DAS?.intitule,
              },
            };

            this.store$.dispatch(
              setTcSmartFormModel({
                storeKey: `${this.storeKey}.filter`,
                model: newModel,
              })
            );

            this.DASFilterFromSelection = selectedRows[0].DAS?.intitule;
          }
        }
      }

      await this.setEstimateBtnState(selectedRows);
      await this.setOrderBtnState(selectedRows);
    });

    this.subscription.add(selectedRowsSubscription);
  }

  async setEstimateBtnState(selectedRows: any[]) {
    let canMakeEstimate = false;

    if (
      this.documentInEdition !== null &&
      this.documentInEdition?.type === DocumentType.Devis
    ) {
      // Get the origin of the article
      const origin = await this.store$
        .select(getCurrentDocumentArticleOrigin)
        .pipe(take(1))
        .toPromise();
      if (
        origin === ArticleOrigin.OrderEstimateOptima ||
        origin === ArticleOrigin.CatalogArticles
      ) {
        canMakeEstimate = true;
      }
    } else {
      if (selectedRows.length > 0) {
        canMakeEstimate = true;
      }
    }

    this.store$.dispatch(setCanMakeEstimate({ canMakeEstimate }));
  }

  async setOrderBtnState(selectedRows: any[]) {
    let canMakeOrder = false;
    if (
      this.documentInEdition !== null &&
      this.documentInEdition?.type === DocumentType.VenteBC
    ) {
      // Get the origin of the article
      const origin = await this.store$
        .select(getCurrentDocumentArticleOrigin)
        .pipe(take(1))
        .toPromise();
      if (
        origin === ArticleOrigin.OrderEstimateOptima ||
        origin === ArticleOrigin.CatalogArticles
      ) {
        canMakeOrder = true;
      }
    } else {
      if (selectedRows.length > 0) {
        canMakeOrder = true;
      }
    }

    this.store$.dispatch(setCanMakeOrder({ canMakeOrder }));
  }

  watchFilterModel() {
    const filterModelSubscription = selectByKey(
      getTcSmartFormCurrentModel,
      this.formStore$,
      `${this.storeKey}.filter`
    ).subscribe((model) => {
      if (!model?.DAS?.intitule && this.DASFilterFromSelection) {
        this.store$.dispatch(
          setTcGridSelection({
            storeKey: this.storeKey,
            selectedRows: [],
          })
        );

        this.DASFilterFromSelection = null;
      }
    });

    this.subscription.add(filterModelSubscription);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getFilterConfig(): TcSmartFilterConfig {
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'mainFilter',
                  className: 'search-icon-filter',
                  templateOptions: {
                    filterOn: ['reference', 'intitule'],
                    filterMultiWord: true,
                    appearance: 'outline',
                  },
                  colSpan: 3,
                }),
                ...(this.isCatalogArticlePage
                  ? [
                      formlyControl({
                        key: this.articleService.DASFilkterKey,
                        type: TcFormlyComponent.TcSmartSelect,
                        templateOptions: {
                          filter: true,
                          focusOnFilter: true,
                          filterPlaceholder: 'search',
                          disabled:
                            this.documentInEdition !== null &&
                            this.documentInEdition.ligne.length > 0,
                          appearance: 'outline',
                          storeKey: `${this.storeKey}.filter.DAS`,
                          defaultValue: '',
                          valueFieldName: 'value',
                          labelFieldName: 'label',
                          resetIfValueMissing: true,
                          dataProvider: {
                            configType: TcConfigTypes.TcDataProvider,
                            providerType: TcDataProviderType.BreezeJs,
                            dataSet: 'Article',
                            fields: 'DAS',
                            distinct: true,
                            filter: {
                              filters: this.defaultArticleFilters,
                            },
                            transformFn: (result) => {
                              return this.articleService.transformToDASCombo(
                                result
                              );
                            },
                          },
                        },
                        colSpan: 1,
                      }),
                    ]
                  : []),
                formlyControl({
                  key: this.articleService.DASOffreFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filter: true,
                    focusOnFilter: true,
                    filterPlaceholder: 'search',
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.offre`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    resetIfValueMissing: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'DAS',
                      distinct: true,
                      filter: {
                        filters: this.defaultArticleFilters,
                      },
                      transformFn: (result) => {
                        return this.articleService.transformToOffreCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: this.isCatalogArticlePage ? 1 : 2,
                }),
                formlyControl({
                  key: this.articleService.DASGammeFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filter: true,
                    focusOnFilter: true,
                    filterPlaceholder: 'search',
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.gamme`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    resetIfValueMissing: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'DAS',
                      distinct: true,
                      filter: {
                        filters: this.defaultArticleFilters,
                      },
                      transformFn: (result) => {
                        return this.articleService.transformToGammeCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 3,
                }),
                formlyControl({
                  key: this.articleService.DASFamilleFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filter: true,
                    focusOnFilter: true,
                    filterPlaceholder: 'search',
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.famille`,
                    resetIfValueMissing: true,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'DAS',
                      distinct: true,
                      filter: {
                        filters: this.defaultArticleFilters,
                      },
                      transformFn: (result) => {
                        return this.articleService.transformToFamilleCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 2,
                }),
                formlyControl({
                  key: this.articleService.DASSousFamilleFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filter: true,
                    focusOnFilter: true,
                    filterPlaceholder: 'search',
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.sousFamille`,
                    resetIfValueMissing: true,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'sousFamille',
                      distinct: true,
                      filter: {
                        filters: this.defaultArticleFilters,
                      },
                      transformFn: (result) => {
                        return this.articleService.transformToSousFamilleCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 2,
                }),
              ],
              colSpan: 12,
            }),
            formlyRow({
              fields: [
                formlyControl({
                  key: 'typeContrat',
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.typeContrat`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'typeContrat',
                      distinct: true,
                      filter: {
                        filters: this.defaultArticleFilters,
                      },
                      transformFn: (result) => {
                        let typeContrats = result.map((res) => res.typeContrat);
                        typeContrats = typeContrats.filter(
                          (typeContrat, index, self) =>
                            typeContrat?.trim() &&
                            self.indexOf(typeContrat) === index
                        );
                        typeContrats = typeContrats.map((typeContrat) => ({
                          value: typeContrat,
                          label: typeContrat,
                        }));

                        return typeContrats;
                      },
                    },
                  },
                  colSpan: 3,
                }),
                formlyControl({
                  key: 'typeRemise',
                  type: TcFormlyComponent.FormlySelect,
                  templateOptions: {
                    appearance: 'outline',
                    filterType: FilterTypesEnum.Equal,
                    options: [
                      {
                        value: 'OUI',
                        label: this.translate.instant('yes'),
                      },
                      {
                        value: 'NON',
                        label: this.translate.instant('no'),
                      },
                    ],
                  },
                  colSpan: 2,
                }),
                formlyControl({
                  key: 'showCommission',
                  type: TcFormlyComponent.FormlyToggle,
                  templateOptions: {
                    options: [
                      {
                        value: true,
                        label: this.translate.instant('true'),
                      },
                      {
                        value: false,
                        label: this.translate.instant('false'),
                      },
                    ],
                    action: updateCatalogGridColumns,
                  },
                  colSpan: 3,
                }),
                formlyComponent({
                  component: TcButtonsListComponent,
                  className: `sale-docs-btn-list ${
                    this.elevage ? '' : 'hidden'
                  }`,
                  componentData: {
                    buttonsList: [
                      {
                        label:
                          this.documentInEdition !== null
                            ? 'select-articles'
                            : 'make-quote',
                        class:
                          this.documentInEdition !== null &&
                          this.documentInEdition?.type !== DocumentType.Devis
                            ? 'hidden'
                            : 'btn-primary btn-text',
                        action: createEstimateFromCatalog,
                        actionPayload: {
                          storeKey: this.storeKey,
                          devisDocNum: this.devisDocNum ?? undefined, //we need undefined and not null if the devisDocNum does not exist
                          origin: this.isCatalogArticlePage
                            ? ArticleOrigin.CatalogArticles
                            : ArticleOrigin.OrderEstimateOptima,
                        },
                        smartStateKey: 'catalogArticle',
                        disableSelector: getEstimateBtnDisabledState,
                        disableStoreKey: 'docButtonsState',
                      },
                      {
                        label:
                          this.documentInEdition !== null
                            ? 'select-articles'
                            : 'make-order',
                        tooltipDisabled: 'make-order-tooltip',
                        class:
                          this.documentInEdition !== null &&
                          this.documentInEdition?.type !== DocumentType.VenteBC
                            ? 'hidden'
                            : 'btn-primary btn-text',
                        action: createOrderFromCatalog,
                        actionPayload: {
                          storeKey: this.storeKey,
                          orderDocNum: this.orderDocNum ?? undefined,
                          origin: this.isCatalogArticlePage
                            ? ArticleOrigin.CatalogArticles
                            : ArticleOrigin.OrderEstimateOptima,
                        },
                        smartStateKey: 'catalogArticle',
                        disableSelector: getOrderBtnDisabledState,
                        disableStoreKey: 'docButtonsState',
                      },
                    ],
                  },
                  colSpan: 4,
                }),
              ],
            }),
          ],
        }),
      ],
    };
  }
}
