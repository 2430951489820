import { XpertService } from './../../../../../services/xpert.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  FilterTypesEnum,
  TcSmartFilterConfig,
  ITcDataService,
  MaterialColor,
  TcFilterDef,
  ListOrder,
} from '@tc/abstract';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  NgRxTcFilterState,
  NgRxTcGridState,
  TcFormlyComponent,
  TcGridCellComponent,
  formlyColumn,
  formlyControl,
  formlyRow,
  getTcFilters,
} from '@tc/core';
import { TcSmartGridComponent, navigate } from '@tc/advanced-components';
import { Observable, Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcSmartButton } from '@tc/buttons';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
  setTcSmartFormModel,
} from '@tc/smart-form';
import { TcDataService } from '@tc/data-store';
import { DataType } from 'breeze-client';
import { DepotStockType } from '../../../../../typings/depot.enum';
import { RestockList } from '../../../interfaces/restock-list-interface';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { selectByKey, selectValueByKey } from '@tc/store';
import { Depot } from '../../../../depot/interfaces/depot.interface';

@Component({
  selector: 'app-restock-grid',
  templateUrl: './restock-grid.component.html',
  styleUrls: ['./restock-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RestockGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'restock-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcFilterState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  buttonsList: TcSmartButton[];
  subscription = new Subscription();

  public numeroDepot: number = null;

  private readonly depotDataService: ITcDataService<any> =
    this.dataService.getService('refDepot', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,emplacement',
    });

  constructor(
    store$: Store<any>,
    private dataService: TcDataService,
    private readonly xpertService: XpertService
  ) {
    super(store$);

    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Retrieves the stock secteur list available to the Xpert user.
   *
   * @returns A promise that resolves to an array of Depot objects representing the stock secteur.
   */
  private async getStockSecteur(): Promise<Depot[]> {
    const xpert = await this.xpertService.getConnectedXpert();
    const depotList = xpert.depot.map((depot) => depot.numero).join(',') ?? '';

    let { data: depotsSecteur } = await this.depotDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.StockSecteur,
            filterType: FilterTypesEnum.Equal,
          },
          {
            key: 'numero',
            value: depotList,
            filterType: FilterTypesEnum.In,
          },
        ],
      },
      {
        key: 'nom',
        order: ListOrder.Asc,
      }
    );

    // Only depots who have documents
    depotsSecteur =
      depotsSecteur.filter((item) => item?.documents?.length > 0) ?? [];

    return depotsSecteur;
  }

  private async loadRestock(): Promise<RestockList[]> {
    const response: RestockList[] = [];

    const currentFilter: TcFilterDef = await selectValueByKey(
      getTcFilters,
      this.filterStore$,
      this.storeKey
    );

    // If a depot is selected, get the documents from refDepot
    const numeroDepot = currentFilter?.filters?.find(
      (item) => item.key === 'numeroDepot'
    )?.value;
    if (!numeroDepot) return response;

    const { data: refDepots } = await this.depotDataService.getData(0, null, {
      filters: [
        {
          key: 'numero',
          value: numeroDepot,
          filterType: FilterTypesEnum.Equal,
        },
      ],
    });

    const refDepotsDocuments = refDepots[0]?.documents ?? [];
    refDepotsDocuments.map((document) => {
      response.push({ ...document, numeroDepot: numeroDepot, description: '' });
    });

    return response;
  }

  async ngOnInit() {
    const subFilter = selectByKey(
      getTcFilters,
      this.filterStore$,
      this.storeKey
    ).subscribe((filterObject) => {
      if (filterObject) {
        const numeroDepot = filterObject?.filters?.find(
          (item) => item.key === 'numeroDepot'
        )?.value;
        if (numeroDepot) {
          this.numeroDepot = numeroDepot;
        } else {
          this.numeroDepot = null;
        }
      }
    });
    this.subscription.add(subFilter);

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      gridOptions: {},
      columnNumberPerDevice: {
        extraSmallDevice: 14,
        smallDevice: 14,
        mediumDevice: 14,
        largeDevice: 14,
        extraLargeDevice: 14,
        extraExtraLargeDevice: 14,
      },
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'Restock',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document',
          data: this.loadRestock.bind(this),
          breezeStructuralTypeExtension: {
            numeroDepot: DataType.Int32,
            description: DataType.String,
          },
        },
      },
      columns: [
        {
          field: 'numeroPiece',
          headerClass: 'text-align-left',
          cellClass: 'text-align-left',
        },
        {
          field: 'date',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
        },
        {
          field: 'dateLivraison',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
        },
        {
          field: 'description',
        },
        {
          field: 'actions',
          sortable: false,
          minWidth: 100,
          width: 120,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.LinkRenderer,
          valueGetter: (params) => [' '],
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.RestockDetail}`,
                  queryParams: [
                    {
                      paramName: 'numeroPiece',
                      dataPropertyName: 'numeroPiece',
                    },
                    {
                      paramName: 'numeroDepot',
                      dataPropertyName: 'numeroDepot',
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
      filterConfig: await this.getFilterConfig(),
    };

    // Autoselect the depot if there is only one
    const depotsSecteur = await this.getStockSecteur();
    if (depotsSecteur.length === 1) {
      const numeroDepot = depotsSecteur[0].numero;

      setTimeout(() => {
        this.store$.dispatch(
          setTcSmartFormModel({
            storeKey: `${this.storeKey}.filter`,
            model: {
              numeroDepot: numeroDepot,
            },
          })
        );
      }, 0);
    }

    super.ngOnInit();
  }

  async getFilterConfig() {
    const depotsSecteur = await this.getStockSecteur();
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'numeroDepot',
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    appearance: 'outline',
                    required: true,
                    storeKey: `${this.storeKey}.filter.numeroDepot`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      sortOrder: {
                        key: 'nom',
                        order: ListOrder.Asc,
                      },
                      dataSet: 'restockDepotList',
                      fields: 'nom',
                      dynamicCollectionFrom: {
                        breezeStructuralType: 'refDepot',
                        data: depotsSecteur,
                      },
                      transformFn: (result) => {
                        const depots = result.map((refDepot) => ({
                          value: refDepot.numero,
                          label: refDepot.code
                            ? refDepot.nom + ' - ' + refDepot.code
                            : refDepot.nom,
                        }));

                        return depots;
                      },
                    },
                  },
                }),
              ],
            }),
          ],
        }),
      ],
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
