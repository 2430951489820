import { selectValueByKey, TcSpinnerService } from '@tc/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  deleteItemSuccess,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NgRxTcGridState, TcTranslateService } from '@tc/core';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import {
  editEstimateArticles,
  recalculatePrice,
  removeEstimateLine,
  saveEstimate,
  updateEstimateDocument,
  updateEstimateLine,
} from './devis.actions';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { getCurrentElevage } from '../../elevage/store/elevage.selectors';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';
import { DocumentService } from '../../../services/document.service';
import * as R from 'ramda';
import { ElevageService } from '../../../services/elevage.service';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormCurrentModel,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import {
  redirectToArticleOrigin,
  setCurrentDocument,
} from '../../article/store/catalog-article.actions';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { PriceService } from '../../../services/price.service';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from '../../article/store/catalog-article.selectors';
import { ArticleOrigin } from '../../commande/store/commande.payloads';

@Injectable()
export class DevisEffects {
  dataStore$: Observable<NgRxTcGridState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly docService: DocumentService,
    private readonly priceService: PriceService,
    private readonly elevageService: ElevageService,
    private readonly spinner: TcSpinnerService,
    private readonly dialog: MatDialog,
    private readonly translateService: TcTranslateService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  editEstimateArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editEstimateArticles),
        tap(async (payload) => {
          const { storeKey, estimateDocNum } = payload;

          // Based on the screen that initiated the order, redirect to the right screen
          this.store$.dispatch(
            redirectToArticleOrigin({
              devisDocNum: estimateDocNum,
              storeKey: storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveEstimate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveEstimate),
        tap(async (payload) => {
          const { storeKey, estimateDocNum } = payload;

          const action = `${storeKey} - spinner`;
          this.spinner.showSpinner(action);

          const elevage = R.clone(
            await this.store$
              .select(getCurrentElevage)
              .pipe(take(1))
              .toPromise()
          );

          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );

          // Security checks
          if (
            this.docService.securityChecksForDocument(this.dialog, document) ===
            false
          ) {
            // Errors, stop the process.
            this.spinner.hideSpinner(action);
            return;
          }

          // Update what need to be updated before saving
          document.montant = this.docService.getMontantFromDocument(document);

          // If document already exist, replace it using assign
          if (estimateDocNum) {
            const existingDocument = elevage.documents.find(
              (doc) => doc.numeroPiece === estimateDocNum
            );
            Object.assign(existingDocument, document);
          } else {
            // Document is new, add it to the elevage
            elevage.documents.push(document);
          }

          this.store$.dispatch(
            updateItem({ storeKey: 'elevage-grid', item: elevage })
          );

          this.docService.traceDocument(document, elevage, 'devis');

          this.actions$
            .pipe(ofType(updateItemSuccess), take(1))
            .subscribe(() => {
              // Clear the document in the store
              this.store$.dispatch(
                setCurrentDocument({ document: null, articleOrigin: null })
              );
              this.elevageService.setStoreCurrentElevage(elevage.numero);
              this.spinner.hideSpinner(action);
              this.showSaveConfirmationDialog();
            });
        })
      ),
    { dispatch: false }
  );

  updateEstimateDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateEstimateDocument),
        tap(async (payload) => {
          const { storeKey } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          const headingFormValue = await selectValueByKey(
            getTcSmartFormCurrentModel,
            this.formStore$,
            storeKey
          );
          if (document && headingFormValue) {
            const origin = await this.store$
              .select(getCurrentDocumentArticleOrigin)
              .pipe(take(1))
              .toPromise();

            // Update the fields from the form config
            document.noteDeReglement = headingFormValue.noteDeReglement;
            this.store$.dispatch(
              setCurrentDocument({ document: document, articleOrigin: origin })
            );
          }
        })
      ),
    { dispatch: false }
  );

  updateEstimateLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateEstimateLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          const line = document.ligne.find(
            (item) => item.article.reference === rowData.article.reference
          );
          if (line) {
            const origin = await this.store$
              .select(getCurrentDocumentArticleOrigin)
              .pipe(take(1))
              .toPromise();

            line.quantite = rowData.quantite;
            this.store$.dispatch(
              setCurrentDocument({ document: document, articleOrigin: origin })
            );
            this.store$.dispatch(recalculatePrice());
          }
        })
      ),
    { dispatch: false }
  );

  removeEstimateLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeEstimateLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          document.ligne = [
            ...document.ligne.filter(
              (item) => item.article.reference !== rowData.article.reference
            ),
          ];

          const origin = await this.store$
            .select(getCurrentDocumentArticleOrigin)
            .pipe(take(1))
            .toPromise();

          this.store$.dispatch(
            setCurrentDocument({ document: document, articleOrigin: origin })
          );
          setTimeout(() => {
            this.store$.dispatch(
              deleteItemSuccess({ storeKey, item: rowData })
            );
            this.store$.dispatch(recalculatePrice());
          });
        })
      ),
    { dispatch: false }
  );

  recalculatePrice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recalculatePrice),
        tap(async () => {
          this.priceService.computePricesForDocument();
        })
      ),
    { dispatch: false }
  );

  private showSaveConfirmationDialog(): void {
    this.dialog
      .open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant(
            'devis-detail-page.saveConfirmation'
          ),
          confirm: this.translateService.instant('globalLabels.close'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.store$.dispatch(
          navigate({
            route: `/${CustomRoutes.ElevageDetail}`,
            queryParams: {},
            storeKey: null,
          })
        );
      });
  }
}
