import { Injectable } from '@angular/core';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { TcDataService } from '@tc/data-store';
import { ElevageDocument } from '../modules/elevage/interfaces/elevage-document.interface';
import { Elevage } from '../modules/elevage/interfaces/elevage.interface';
import { Xpert } from '../modules/xpert/interfaces/xpert.interface';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { formatToPrecision, TcTranslateService } from '@tc/core';
import moment from 'moment';
import { DiscountType } from '../typings/discount-type.enum';
import { DocumentLigne } from '../modules/elevage/interfaces/document-ligne.interface';
import { DocumentType } from '../typings/document.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private readonly elevageDataService: ITcDataService<any> =
    this.dataService.getService('Elevage', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Elevage',
      fields: 'documents',
    });

  constructor(
    private dataService: TcDataService,
    private readonly translateService: TcTranslateService
  ) {}

  public async generateNewNumXpertMobile(xpert: Xpert) {
    // #XPR-BUG-015 / #XPR-EX-219 : It was decided to use a date with milliseconds instead of last doc generated number because of performances issues.
    const time = moment().format('YYYYMMDDHHmmssSSS');
    return `${xpert.numero}-${time}`;
  }

  public async getLastTempNumeroPiece() {
    const { data: elevages } = await this.elevageDataService.getData(0, null, {
      filters: [
        {
          key: 'documents.numeroPiece',
          value: 'null',
          filterType: FilterTypesEnum.IsNotNullOrEmptyString,
        },
      ],
    });

    let docs = (elevages as Elevage[]).reduce(
      (prev, elevage) => [...prev, ...elevage.documents],
      []
    ) as ElevageDocument[];

    docs = docs.filter((doc) => !isNaN(+doc.numeroPiece));

    const numPieces = docs
      .map((doc) => doc.numeroPiece)
      .sort((num1, num2) =>
        num1?.localeCompare(num2, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );

    return numPieces.length ? Number(numPieces[numPieces.length - 1]) : 0;
  }

  /**
   * Method used to open errors popup before creating an order / estimate / delivery bond
   * @param dialog MatDialog Dialog object used by the store
   * @param document ElevageDocument
   * @returns boolean
   */
  public securityChecksForDocument(
    dialog: MatDialog,
    document: ElevageDocument
  ): boolean {
    let error: string | null = null;

    // At least one article line
    if (!document.ligne || document.ligne.length === 0) {
      error = 'no-article-error';
    } else {
      // No quantity at zero or undefined
      const noQuantities = document.ligne.filter(
        (item) =>
          item.quantite === 0 ||
          item.quantite === null ||
          item.quantite === undefined
      );
      if (noQuantities.length > 0) {
        error = 'no-quantity-error';
      } else if (!document.prescripteur) {
        error = 'no-prescripteur-error';
      }
    }

    if (error) {
      dialog.open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant(error),
          confirm: this.translateService.instant('globalLabels.close'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      });

      return false;
    }

    return true;
  }

  /**
   * Return the montant of the document based of the article lines inside
   * @param document ElevageDocument
   * @returns number
   */
  getMontantFromDocument(document: ElevageDocument): number {
    const docMontant =
      document.ligne.reduce(
        (previousValue, ligneObject) =>
          previousValue +
          (ligneObject.prixUnitaire ?? 0) * (ligneObject.quantite ?? 0),
        0
      ) ?? 0;

    const docDiscount =
      document.ligne.reduce((prev, line) => {
        let discountAmount = line.montantRemise;
        if (line.typeRemise === DiscountType.Pourcentage) {
          discountAmount =
            (line.quantite * line.prixUnitaire * line.montantRemise) / 100;
        }

        const sum = prev + discountAmount;
        return sum;
      }, 0) ?? 0;

    return docMontant - docDiscount;
  }

  /**
   * Get total discount from the document lines
   * @param document ElevageDocument | null
   * @returns number
   */
  getTotalDiscount(document: ElevageDocument | null) {
    let totalDiscount = 0;
    if (document !== null && document?.ligne.length > 0) {
      totalDiscount = document.ligne.reduce((prev, line) => {
        let discountAmount = line.montantRemise;
        if (line.typeRemise === DiscountType.Pourcentage) {
          discountAmount =
            (line.quantite * line.prixUnitaire * line.montantRemise) / 100;
        }

        const sum = prev + discountAmount;
        return sum;
      }, 0);
    }

    return totalDiscount;
  }

  /**
   * Get total price excluding taxes (HT) from the document lines
   * @param document ElevageDocument | null
   * @returns number
   */
  getTotalPriceExclTax(document: ElevageDocument | null) {
    let totalPrice = 0;
    if (document !== null && document?.ligne.length > 0) {
      totalPrice = document.ligne.reduce(
        (prev, line) => prev + line.prixUnitaire * line.quantite,
        0
      );
    }

    return formatToPrecision(totalPrice, 3);
  }

  /**
   * Get total with discount from the document lines
   * @param document ElevageDocument | null
   * @returns number
   */
  getTotalPriceWithDiscount(document: ElevageDocument | null) {
    const totalDiscount = this.getTotalDiscount(document);
    const totalPrice = this.getTotalPriceExclTax(document);

    return totalPrice - totalDiscount;
  }

  /**
   * Get remaining quantity to delivier based on the previously registered documents
   * @param ligne DocumentLigne
   * @param document ElevageDocument
   * @param elevage Elevage
   * @returns number
   */
  getQuantityRemainingToDeliver(
    ligne: DocumentLigne,
    document: ElevageDocument,
    elevage: Elevage
  ) {
    let quantiteRestante = 0;
    const associatedBLs = elevage.documents.filter(
      (doc) =>
        doc.type === DocumentType.VenteBL &&
        doc.ligne.find(
          (ligneObject) =>
            ligneObject.numeroPieceBonCommandeOrigine === document.numeroPiece
        )
    );
    const associatedBLLines = associatedBLs
      .reduce<DocumentLigne[]>((prev, BL) => prev.concat(BL.ligne), [])
      .filter(
        (ligneObject) =>
          ligneObject.article.reference === ligne.article.reference
      );
    const quantityDelivered = associatedBLLines.reduce(
      (prev, BLLine) => prev + BLLine.quantite,
      0
    );
    if (associatedBLLines.length > 0) {
      quantiteRestante = ligne.quantite - quantityDelivered;
    } else {
      // No order yet, quantity to deliver equals to quantity inputed
      quantiteRestante = ligne.quantite;
    }

    return quantiteRestante;
  }

  /**
   * Calculate the price for a line. As this method is used by the grid, the type is any
   * because theses fields does not exists on DocumentLigne but rely on property that exists on it.
   * It's directly setting the values inside the object.
   * @param ligne any
   */
  setLignePricesForGrid(ligne: any) {
    ligne.prixUnitaireRemise = !ligne.montantRemise
      ? ligne.prixUnitaire
      : +ligne.typeRemise === DiscountType.Pourcentage
      ? ligne.prixUnitaire - (ligne.prixUnitaire * ligne.montantRemise) / 100
      : +ligne.typeRemise === DiscountType.Montant
      ? ligne.prixUnitaire - ligne.montantRemise
      : ligne.prixUnitaire;
    ligne.prixUnitaireTotal = ligne.prixUnitaireRemise * ligne.quantite;
  }
}
