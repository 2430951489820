import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  ITcDataService,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  TcGridCellComponent,
  TcTranslateService,
  TcValidatorType,
} from '@tc/core';
import * as R from 'ramda';
import { DataType } from 'breeze-client';
import { TcDataService } from '@tc/data-store';
import { LocalStorageKeys } from '../../../../../typings/local-storage-keys.enum';
import { TcLocalStorageService } from '@tc/local-storage';
import { Article } from '../../../../article/interfaces/article.interface';
import { ModeSuiviStock } from '../../../../../typings/mode-suivi-stock.enum';
import { TcSmartButton } from '@tc/buttons';
import {
  resetLivraisonRowQuantity,
  setExceededQuantitiesState,
  verifyDeliveryWithoutOrder,
} from '../../../store/livraison.actions';
import { getExceededQuantitiesState } from '../../../store/livraison.selectors';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { Subscription } from 'rxjs';
import { DepotStockType } from '../../../../../typings/depot.enum';
import { StockService } from './../../../../stock/services/stock.services';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { Movement } from '../../../../article/interfaces/movement.interface';
import { setLotId } from '../../../../../typings/lot-base.interface';

@Component({
  selector: 'app-livraison-grid',
  templateUrl: './livraison-grid.component.html',
  styleUrls: ['./livraison-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LivraisonGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'livraison-grid';

  subscription = new Subscription();
  elevage: Elevage;

  articleLines: (Article & {
    numeroLot?: string;
    availableQuantity?: number;
    datePeremention?: string | null;
    complement?: string | null;
    dateFabrication?: string | null;
    cbMarq?: number | null;
  })[] = [];

  exceededQuantitiesArticleIds: string[] = [];

  buttonsList: TcSmartButton[] = [
    {
      label: 'livraison-grid.button',
      ionIcon: 'checkmark-outline',
      class: `mat-raised-button btn-primary`,
      disableStoreKey: 'quantities',
      disableSelector: getExceededQuantitiesState,
      smartStateKey: 'livraison',
      action: verifyDeliveryWithoutOrder,
      actionPayload: {
        storeKey: this.storeKey,
      },
    },
  ];

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'reference,intitule,stock,tarif,conditionnementXpert',
    }
  );

  private depotDataService: ITcDataService<any> = this.dataService.getService(
    'refDepot',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,emplacement',
    }
  );

  constructor(
    store$: Store<any>,
    public readonly translate: TcTranslateService,
    private readonly dataService: TcDataService,
    private readonly localStorage: TcLocalStorageService,
    private readonly stockService: StockService
  ) {
    super(store$);
    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);
  }

  async loadVehicleStock() {
    const currentVehicleCode = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );

    const { data: depots } = await this.depotDataService.getData(0, null, {
      filters: [
        {
          key: 'typeDeStock',
          value: DepotStockType.StockSecteur,
          filterType: FilterTypesEnum.Equal,
        },
      ],
    });

    const relatedDepot = depots.find((depot) =>
      depot.emplacement.some((empl) => empl.code === currentVehicleCode)
    );

    let { data: articles } = await this.articleDataService.getData(0, null, {
      filters: [
        {
          key: 'modeSuiviStock',
          value: `${ModeSuiviStock.SuiviCMUP},${ModeSuiviStock.SuiviLotsDLUO}`,
          filterType: FilterTypesEnum.In,
        },
      ],
    });

    // Filter the articles that have the related depot in their stock.
    // Filtering on an array of objects is not supported by breeze, so we have to do it manually.
    // Hopefully, the data is already in memory, so the performance impact is negligible.
    articles = articles.filter((article: Article) =>
      article.stock.some((item) => {
        return item.depot.numero === relatedDepot.numero;
      })
    );

    const suiviCMUPArticles = articles.filter(
      (article) => article.modeSuiviStock === ModeSuiviStock.SuiviCMUP
    );

    suiviCMUPArticles.forEach((article) => {
      article.availableQuantity = this.stockService.getArticleRemainingQuantity(
        article,
        null,
        null,
        currentVehicleCode
      );
    });

    const suiviLotArticles = articles.filter(
      (article) => article.modeSuiviStock === ModeSuiviStock.SuiviLotsDLUO
    );
    const articlesRefsToRemove: string[] = [];
    const articlesToAdd: any[] = [];

    //For the articles with ModeSuiviStock = SuiviLotsDLUO, distinct the articles by lot and calculate the available quantity based on the movement lines
    suiviLotArticles.forEach((article) => {
      const stock = article.stock.find((st) =>
        st.depot.emplacement.some((empl) => empl.code === currentVehicleCode)
      );
      const emplacement = stock?.depot.emplacement.find(
        (empl) => empl.code === currentVehicleCode
      );

      if (stock?.depot.movement.length || 0 > 0) {
        articlesRefsToRemove.push(article.reference);

        stock?.depot.movement.forEach((movement: Movement, index) => {
          if (movement.quantiteRestante > 0) {
            // Do not use ramda clone here, performances issues. Article nested objects can be very heavy. A true deep copy will be too slow.
            const newArticle = JSON.parse(JSON.stringify(article));
            delete newArticle._id;
            newArticle.availableQuantity = movement.quantiteRestante;
            newArticle.numeroLot = movement.numeroLot;
            newArticle.dateFabrication = movement.dateFabrication;
            newArticle.datePeremention = movement.datePeremention;
            newArticle.complement = movement.complement;
            newArticle.cbMarq = movement.cbMarq;
            newArticle.lotId = movement.lotId;
            articlesToAdd.push(newArticle);
          }
        });
      } else {
        article.availableQuantity = emplacement?.quantiteStockEmplacement ?? 0;
      }
    });

    articles = articles.filter(
      (article) =>
        articlesRefsToRemove.indexOf(article.reference) < 0 ||
        article.availableQuantity === 0
    );

    articlesToAdd.forEach(
      (article) => (article.lotId = `${article.numeroLot}`)
    );
    setLotId(articlesToAdd);

    articles.push(...articlesToAdd);

    // XAS-75 - Filter out articles with no availability in the car
    //          availableQuantity is calculated based on the current car
    articles = articles.filter((a) => a.availableQuantity > 0);

    //Set the quantities for each article to 0 by default when opening the page
    articles.forEach((article) => {
      article.quantite = 0;

      // For performances, remove the stock from the article that are not used in the grid. We already calculated the available quantity.
      // If we don't clear it, Ramda will have a really hard time to clone the rows of the grid.
      article.stock = [];
    });
    return articles;
  }

  async ngOnInit() {
    this.store$.dispatch(setExceededQuantitiesState({ exceeded: false }));
    this.articleLines = await this.loadVehicleStock();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'livraison-detail-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      columnNumberPerDevice: {
        extraSmallDevice: 7,
        smallDevice: 7,
        mediumDevice: 7,
        largeDevice: 7,
        extraLargeDevice: 7,
        extraExtraLargeDevice: 7,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageLivraison',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Article',
          data: this.articleLines,
          breezeStructuralTypeExtension: {
            lotId: DataType.String,
            numeroLot: DataType.String,
            availableQuantity: DataType.Int32,
            quantite: DataType.Int32,
            datePeremention: DataType.String,
            dateFabrication: DataType.String,
            complement: DataType.String,
            cbMarq: DataType.Int32,
          },
        },
      },
      columns: [
        {
          field: 'reference',
          maxWidth: 270,
          comparator: gridComparatorForString,
          sort: 'asc',
        },
        {
          field: 'intitule',
          minWidth: 250,
          cellRenderer: (params) =>
            `<span title="${params?.data?.intitule}">${params?.data?.intitule}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: 'conditionnementXpert',
          maxWidth: 120,
          comparator: gridComparatorForString,
        },
        {
          field: 'lotId',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          minWidth: 210,
          comparator: gridComparatorForString,
        },
        {
          field: 'quantite',
          minWidth: 80,
          cellClass: 'input text-align-right quantityCell',
          headerClass: 'text-align-right',
          cellRenderer: TcGridCellComponent.MatInputEditor,
          cellRendererParams: {
            type: 'text',
            smartState: {
              storeKey: this.storeKey,
              field: 'quantite',
            },
            mask: '0*',
            selectTextOnClick: true,
            disableFocusAfterInit: true,
            beforeChange: (value) => {
              // To avoid empty values and leading zeros
              if (value === '') {
                return 0;
              }
              const result = parseInt(value, 10);
              return isNaN(result) || result < 0 ? 0 : result;
            },
            validators: [
              (value, data) => {
                if (value > +data.availableQuantity) {
                  this.exceededQuantitiesArticleIds.push(data._id);
                  this.store$.dispatch(
                    setExceededQuantitiesState({ exceeded: true })
                  );
                  return {
                    type: TcValidatorType.Max,
                    message: `${this.translate.instant(
                      'livraison-grid.error.exceedQuantity'
                    )}: ${data.availableQuantity}`,
                  };
                }

                const index = this.exceededQuantitiesArticleIds.indexOf(
                  data._id
                );
                if (index >= 0) {
                  this.exceededQuantitiesArticleIds.splice(index, 1);
                }

                if (this.exceededQuantitiesArticleIds.length === 0) {
                  this.store$.dispatch(
                    setExceededQuantitiesState({ exceeded: false })
                  );
                }
                return null;
              },
            ],
            onValueChangeDelayInMillis: 1000,
          },
          comparator: gridComparatorForNumber,
        },
        {
          field: 'actions',
          sortable: false,
          width: 120,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'trash-outline',
                tooltip: 'trash-tooltip',
                action: resetLivraisonRowQuantity,
              },
            ],
          },
        },
      ],
    };

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
