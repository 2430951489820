import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  FilterTypesEnum,
  TcSmartFilterConfig,
  TcSmartFormConfig,
  ITcDataService,
  ITcDataResult,
  MaterialColor,
} from '@tc/abstract';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
  TcGridReadyEvent,
  TcTranslateService,
} from '@tc/core';
import { TcSmartGridComponent, navigate } from '@tc/advanced-components';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { merge, Subscription } from 'rxjs';
import { PinnedRowPosition } from '@tc/abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { ActivatedRoute } from '@angular/router';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import {
  TcDataService,
  deleteItemSuccess,
  loadTcDataSuccess,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import * as R from 'ramda';
import { DataType } from 'breeze-client';
import {
  getTcSmartFormIsChangedState,
  initTcSmartForm,
  markTcSmartFormStateAsChanged,
  setTcSmartFormModel,
  updateTcSmartFormCurrentModel,
} from '@tc/smart-form';
import { DepotStockType } from '../../../../../typings/depot.enum';
import { DAS } from '../../../../../typings/DAS.enum';
import { XpertService } from '../../../../../services/xpert.service';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from '../../../../article/store/catalog-article.selectors';
import { first, take } from 'rxjs/operators';
import { TcLocalStorageService } from '@tc/local-storage';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import {
  editOrderArticles,
  removeOrderLine,
  saveOrder,
  updateOrderDocument,
  updateOrderLine,
} from '../../../store/commande.actions';
import { PaymentMode } from '../../../../../typings/payment-mode.enum';
import { Actions, ofType } from '@ngrx/effects';
import { ArticleOrigin } from '../../../store/commande.payloads';
import {
  createOrderFromEstimate,
  redirectToArticleOrigin,
  setCurrentDocument,
} from '../../../../article/store/catalog-article.actions';
import { DocumentService } from '../../../../../services/document.service';
import {
  DocumentDomaine,
  DocumentType,
} from '../../../../../typings/document.enum';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import {
  decimalsForXpertMobile,
  emptyDecimalFormatForXpertMobile,
} from '../../../../../shared/decimals';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { DOCUMENT } from '@angular/common';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { EncaissementPopupComponent } from '../../../../encaissement/components/smart/encaissement-popup/encaissement-popup.component';
import { setCurrentEncaissement } from '../../../../encaissement/store/encaissement.actions';

@Component({
  selector: 'app-commande-detail-grid',
  templateUrl: './commande-detail-grid.component.html',
  styleUrls: ['./commande-detail-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommandeDetailGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'commande-detail-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  buttonsList: TcSmartButton[] = [];
  addBtn: TcSmartButton;
  elevage: Elevage;
  subscription = new Subscription();

  containerOptions: {
    value: string;
    label: string;
  }[] = [];

  readonly = false;
  allowContainerSelection = false;
  orderDocumentView: ElevageDocument | null;
  private refresh: () => void;
  // Start the refreshLaunched to true to prevent unnecessary refresh on page loading witch in certain cases can cause a row duplication bug in the grid
  private refreshLaunched = true;
  orderDocNum: string = null;
  devisDocNum: string = null;

  private refDepotDataService: ITcDataService<any> =
    this.dataService.getService('refDepot', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero, code, nom',
    });

  private collaborateurDataService: ITcDataService<any> =
    this.dataService.getService('Collaborateur', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Collaborateur',
      fields: 'depot.numero,territoire.groupe.secteur',
    });

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'depot',
    }
  );

  private isUpdateItemActionTriggered = false;
  private ignoreConfirmation = false;

  constructor(
    protected store$: Store<any>,
    private activatedRoute: ActivatedRoute,
    private readonly dataService: TcDataService,
    private readonly translate: TcTranslateService,
    private readonly xpertService: XpertService,
    private readonly localStorage: TcLocalStorageService,
    private readonly actions$: Actions,
    private readonly docService: DocumentService,
    @Inject(DOCUMENT) private documentDOM: Document,
    private dialog: MatDialog
  ) {
    super(store$);

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);

    const documentSubscription = this.store$
      .pipe(select(getCurrentDocument))
      .subscribe((document) => {
        this.orderDocumentView = document;
        this.checkArticleDAS();
        this.setReadonly();
        this.refreshFormAndGrid();
      });

    this.subscription.add(documentSubscription);
  }

  /**
   * Function to sync form and grid data with the loaded document
   */
  public refreshFormAndGrid() {
    if (this.refresh) {
      // Prevent multiple refresh at the same time on page loading
      if (this.refreshLaunched === false) {
        this.refreshLaunched = true;
        this.setFormValues();
        this.refresh();
      }
    }
  }

  private getRouteForRedirection() {
    return this.orderDocumentView?.ligne.some(
      (item) => !!item?.numeroPieceDevisOrigine
    ) || !!this.devisDocNum
      ? CustomRoutes.CommandesList // if old estimate number or devisDocNum, document was created from estimate. Redirect to commandes list after save.
      : CustomRoutes.ElevageDetail; // if new or update existing order, redirect to elevage detail
  }

  private setPageButtons() {
    this.buttonsList = [
      {
        label: 'commande-detail-grid.button.cancel',
        ionIcon: 'close',
        color: MaterialColor.Warn,
        type: MaterialButtonType.Raised,
        action: () => {
          // Display prompt confirmation
          const dialog = this.dialog.open(TcPromptDialogComponent, {
            width: '37.5em',
            data: {
              title: 'prompt.title',
              text: 'commande-detail-grid.button.cancel.prompt-message',
              disableClose: true,
            },
          });

          dialog.afterClosed().subscribe((result) => {
            if (!result) return;

            // Reset the state and redirect to the appropriate page
            this.store$.dispatch(redirectToArticleOrigin({ reset: true }));
          });
        },
      },
      // XPR-173 - comment out encaissement functionality as it was disabled for prod and preprod
      // {
      //   label: 'validate-order-encaissement',
      //   ionIcon: 'checkmark-outline',
      //   class:
      //     'mat-raised-button btn-secondary encaissement-button btn-disabled',
      //   action: () => {
      //     const dialog = this.dialog.open(EncaissementPopupComponent, {
      //       data: {
      //         total: 0,
      //       },
      //       width: '30em',
      //     });
      //     dialog.afterClosed().subscribe((result) => {
      //       if (result && result?.setInStore === true) {
      //         // Launch the saving process of the grid. If encaissement is present in the store, it will be saved as well.
      //         this.store$.dispatch(
      //           saveOrder({
      //             storeKey: this.storeKey,
      //             orderDocNum: this.orderDocNum,
      //             route: this.getRouteForRedirection(),
      //           })
      //         );
      //       }
      //     });
      //   },
      // },
      {
        label: 'commande-detail-grid.button',
        ionIcon: 'checkmark-outline',
        class:
          'mat-raised-button btn-primary commande-validate-button btn-disabled',
        action: saveOrder,
        actionPayload: {
          storeKey: this.storeKey,
          orderDocNum: this.orderDocNum,
          route: this.getRouteForRedirection(),
        },
      },
    ];

    this.addBtn = {
      ionIcon: 'add-outline',
      color: MaterialColor.Primary,
      type: MaterialButtonType.Fab,
      class: this.readonly ? 'btn-disabled' : '',
      action: editOrderArticles,
      actionPayload: {
        orderDocNum: this.orderDocNum,
        storeKey: this.storeKey,
      },
    };
  }

  async ngOnInit() {
    this.orderDocNum = this.activatedRoute.snapshot.queryParams['orderDocNum'];
    this.devisDocNum = this.activatedRoute.snapshot.queryParams['devisDocNum'];

    // Get the origin of the article
    const origin =
      (await this.store$
        .select(getCurrentDocumentArticleOrigin)
        .pipe(take(1))
        .toPromise()) ?? ArticleOrigin.OrderEstimateOptima;

    // Load existing devis into the store and morph it into a order (case of creating an order from a devis)
    if (
      this.devisDocNum &&
      (this?.orderDocumentView?.numeroPiece !== this.devisDocNum ||
        this?.orderDocumentView?.domaine !== DocumentDomaine.Vente ||
        this?.orderDocumentView?.type !== DocumentType.VenteBC)
    ) {
      this.store$.dispatch(
        createOrderFromEstimate({
          storeKey: this.storeKey,
          devisDocNum: this.devisDocNum,
        })
      );
    }

    // Load existing order into the store if not set (case of viewing existing devis)
    if (
      this.orderDocNum &&
      (this?.orderDocumentView?.numeroPiece !== this.orderDocNum ||
        this?.orderDocumentView?.domaine !== DocumentDomaine.Vente ||
        this?.orderDocumentView?.type !== DocumentType.VenteBC)
    ) {
      const order = this.elevage.documents.find(
        (doc) =>
          doc.numeroPiece === this.orderDocNum &&
          doc.domaine === DocumentDomaine.Vente &&
          doc.type === DocumentType.VenteBC
      );
      if (!order) throw new Error('Order document not found');
      this.store$.dispatch(
        setCurrentDocument({ document: order, articleOrigin: origin })
      );
    }

    await this.setReadonly();
    this.setPageButtons();

    await this.checkArticleDAS();
    if (this.allowContainerSelection) {
      await this.preparecontainerOptions();
    }

    this.initOrderHeading();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'commande-detail-grid',
      gridOptions: {},
      columnNumberPerDevice: {
        extraSmallDevice: 9,
        smallDevice: 9,
        mediumDevice: 9,
        largeDevice: 9,
        extraLargeDevice: 9,
        extraExtraLargeDevice: 9,
      },
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageCommandeDetail',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document.ligne',
          data: this.getOrderLines.bind(this),
          breezeStructuralTypeExtension: {
            quantiteRestante: DataType.Double,
            prixUnitaireRemise: DataType.Double,
            prixUnitaireTotal: DataType.Double,
          },
        },
      },
      pinnedRowsConfig: [
        {
          rowId: 'total-row-excl-discount',
          cssClass: 'row-total-excl-discount',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('amount-excl-tax'),
              colSpan: 6,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService
                  .getTotalPriceExclTax(this.orderDocumentView)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`,
              cellClass: 'text-align-right',
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
        {
          rowId: 'discount-row',
          cssClass: 'pinned-row-gray',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('global-discount-amount'),
              colSpan: 6,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService
                  .getTotalDiscount(this.orderDocumentView)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`,
              cellClass: 'text-align-right',
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
        {
          rowId: 'total-row',
          cssClass: 'row-total',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('total'),
              colSpan: 6,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService
                  .getTotalPriceWithDiscount(this.orderDocumentView)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`,
              cellClass: 'text-align-right',
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
      ],
      columns: [
        {
          field: 'article.reference',
          minWidth: 150,
          maxWidth: 150,
          sort: 'asc',
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.article?.reference
              ? valueA?.article?.reference
              : '';
            const valueBCleaned = valueB?.article?.reference
              ? valueB?.article?.reference
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'libelle',
          flex: 1,
          cellRenderer: (params) =>
            `<span title="${params?.data?.libelle}">${params?.data?.libelle}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: 'conditionnementXpert',
          width: 80,
          maxWidth: 80,
          comparator: gridComparatorForString,
        },
        {
          field: 'quantite',
          width: 160,
          maxWidth: 160,
          cellClass: `quantityCell text-align-right${
            this.readonly ? ' ag-cell-disabled' : ''
          }`,
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
          cellRenderer: TcGridCellComponent.MatInputEditor,
          cellRendererParams: {
            type: 'text',
            smartState: {
              storeKey: this.storeKey,
              field: 'quantite',
            },
            mask: '0*',
            selectTextOnClick: true,
            beforeChange: (value) => {
              // To avoid empty values and leading zeros
              if (value === '') {
                return 0;
              }
              const result = parseInt(value, 10);
              return isNaN(result) || result < 0 ? 0 : result;
            },
            onValueChange: (value, data) => {
              this.store$.dispatch(
                updateOrderLine({ storeKey: this.storeKey, rowData: data })
              );
            },
            onValueChangeDelayInMillis: 1000,
          },
        },
        {
          field: 'quantiteRestante',
          width: 160,
          maxWidth: 160,
          cellClass: ' text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaire',
          width: 160,
          maxWidth: 160,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaire
                ? `${params.data.prixUnitaire.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaireRemise',
          width: 160,
          maxWidth: 160,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaireRemise
                ? `${params.data.prixUnitaireRemise.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaireTotal',
          width: 160,
          maxWidth: 160,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaireTotal
                ? `${params.data.prixUnitaireTotal.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'actions',
          sortable: false,
          minWidth: 100,
          maxWidth: 100,
          headerClass: 'text-align-center',
          cellClass: `text-align-center${
            this.readonly ? ' ag-cell-disabled' : ''
          }`,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'trash-outline',
                tooltip: 'trash-tooltip',
                action: removeOrderLine,
                actionPayload: {
                  storeKey: this.storeKey,
                },
              },
            ],
          },
          pinnedRowCellRenderer: () => '',
        },
      ],
    };

    super.ngOnInit();

    this.subscription.add(
      this.actions$.pipe(ofType(updateItemSuccess), take(1)).subscribe(() => {
        this.isUpdateItemActionTriggered = true;
      })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(deleteItemSuccess), take(1)).subscribe(() => {
        this.isUpdateItemActionTriggered = true;
      })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(loadTcDataSuccess)).subscribe(() => {
        this.refreshLaunched = false;
      })
    );

    this.subscription.add(
      this.actions$
        .pipe(ofType(markTcSmartFormStateAsChanged))
        .subscribe(() => {
          this.enableValidateButton();
        })
    );

    this.subscription.add(
      merge(
        this.actions$.pipe(ofType(updateItem)),
        this.actions$.pipe(ofType(editOrderArticles))
      )
        .pipe(take(1))
        .subscribe(() => {
          this.ignoreConfirmation = true;
        })
    );

    this.subscription.add(
      this.actions$
        .pipe(ofType(updateTcSmartFormCurrentModel))
        .subscribe(() => {
          this.store$.dispatch(
            updateOrderDocument({ storeKey: this.storeKey })
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();

    // In case of readonly documents, you don't want them to be able to add more articles by going to article list. Purge the store.
    if (this.readonly) {
      this.store$.dispatch(
        setCurrentDocument({ document: null, articleOrigin: null })
      );
      this.store$.dispatch(setCurrentEncaissement({ encaissement: null }));
    }
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
  }

  public async getOrderLines(): Promise<any[]> {
    const toDisplay = [];
    const lignes = R.clone(this.orderDocumentView?.ligne ?? []);
    if (
      this.orderDocumentView !== null &&
      this.orderDocumentView?.ligne?.length > 0
    ) {
      const articleFilter = lignes
        .map((ligne) => ligne.article.reference)
        .join(',');

      const { data: articles } = await this.articleDataService.getData(
        0,
        null,
        {
          filters: [
            {
              key: 'reference',
              value: articleFilter,
              filterType: FilterTypesEnum.In,
            },
          ],
        }
      );
      lignes.forEach((ligne: DocumentLigne) => {
        const updatedLigne = R.clone(ligne);
        this.docService.setLignePricesForGrid(updatedLigne);

        // Final object to display in the grid
        updatedLigne.quantiteRestante =
          this.docService.getQuantityRemainingToDeliver(
            updatedLigne,
            this.orderDocumentView,
            this.elevage
          );
        updatedLigne.libelle = articles.find(
          (article) => article.reference === updatedLigne.article.reference
        )?.intitule;
        toDisplay.push(updatedLigne);
      });
    }

    // Only declared as a promise to comply to the interface. Returns the data directly.
    return toDisplay;
  }

  initOrderHeading() {
    const formConfig: TcSmartFormConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.storeKey,
      autoSubmit: false,
      fieldConfigs: this.getOrderHeadingFields(),
    };

    this.store$.dispatch(
      initTcSmartForm({
        storeKey: formConfig.storeKey,
        config: formConfig,
      })
    );

    this.setFormValues();
  }

  public setFormValues() {
    let noteDeReglement;

    if (this.elevage.reglement.intitule !== 'Prélèvement') {
      noteDeReglement = PaymentMode.Paiement1X;
    }

    const formDefaultValue = {
      createdOn: this.orderDocumentView?.createdOn || new Date().toISOString(),
      numeroPiece: this.orderDocumentView?.numeroPiece,
      date: this.orderDocumentView?.date || new Date().toISOString(),
      dateLivraison: this.orderDocumentView?.dateLivraison || undefined,
      noteDeReglement:
        noteDeReglement ||
        this.orderDocumentView?.noteDeReglement ||
        PaymentMode.Paiement1X,
      adresseLivraison: this.orderDocumentView?.siteLivraison?._id
        ? parseFloat(this.orderDocumentView?.siteLivraison?._id)
        : undefined,
    };

    this.store$.dispatch(
      setTcSmartFormModel({
        storeKey: this.storeKey,
        model: formDefaultValue,
      })
    );
  }

  preparecontainerOptions() {
    return new Promise(async (resolve) => {
      this.containerOptions = [];
      if (this.elevage.cuve) {
        this.containerOptions.push({
          label: this.elevage.cuve,
          value: this.elevage.cuve,
        });
      }

      const loggedXpertPromise = this.xpertService.getConnectedXpert();
      const collaborateursPromise = this.collaborateurDataService.getData(
        0,
        null,
        {
          filters: [
            {
              key: 'territoire.groupe.secteur.numero',
              value: this.elevage.territoire.groupe.secteur.numero,
              filterType: FilterTypesEnum.Equal,
            },
          ],
        }
      );

      await Promise.all([loggedXpertPromise, collaborateursPromise])
        .then((collaborateurs) => {
          const collabData = [collaborateurs[0], ...collaborateurs[1].data];
          const depotsFilter = collabData.reduce(
            (acc, collab) =>
              Array.from(
                new Set([...acc, ...collab.depot.map((depot) => depot.numero)])
              ),
            []
          );
          if (!depotsFilter.length) {
            return new Promise<ITcDataResult<any>>((resolveFn) =>
              resolveFn({ data: [], total: 0 })
            );
          }

          return this.refDepotDataService.getData(0, null, {
            filters: [
              {
                key: 'numero',
                value: depotsFilter.join(','),
                filterType: FilterTypesEnum.In,
              },
              {
                key: 'typeDeStock',
                value: DepotStockType.CuveXpert,
                filterType: FilterTypesEnum.Equal,
              },
            ],
          });
        })
        .then((depots) => {
          this.containerOptions = [
            ...this.containerOptions,
            ...depots?.data.map((refDepot) => ({
              label: `${
                refDepot.code
                  ? refDepot.nom + ' - ' + refDepot.code
                  : refDepot.nom
              }`,
              value: refDepot.numero,
            })),
          ];
          this.containerOptions = R.uniqBy(
            R.prop('value'),
            this.containerOptions
          );
        });
      resolve(0);
    });
  }

  async setReadonly(): Promise<boolean> {
    const lastSyncDate = await this.localStorage.get('lastSyncDate');

    if (!this.orderDocumentView) {
      this.readonly = false;
      this.enableValidateButton();
      return false;
    }

    if (
      this.orderDocumentView.createdOn &&
      new Date(this.orderDocumentView.createdOn) > new Date(lastSyncDate)
    ) {
      this.readonly = false;
      this.enableValidateButton();
      return false;
    } else {
      this.readonly = true;
      this.enableValidateButton();
      return true;
    }
  }

  private getOrderHeadingFields() {
    const formFieldConfigs: FormlyFieldConfig[] = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'createdOn',
                className: 'date-picker',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  matIcon: 'calendar_today',
                  disabled: true,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'numeroPiece',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  label: 'numeroPiece',
                  type: 'text',
                  appearance: 'outline',
                  disabled: true,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'date',
                className: 'date-picker',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  matIcon: 'calendar_today',
                  datepickerOptions: {
                    min: new Date(),
                  },
                  disabled: this.readonly,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'dateLivraison',
                className: 'date-picker',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  matIcon: 'calendar_today',
                  datepickerOptions: {
                    min: new Date(),
                  },
                  disabled: this.readonly,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'noteDeReglement',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  appearance: 'outline',
                  options: Object.values(PaymentMode).map((paymentMode) => ({
                    value: paymentMode,
                    label: paymentMode,
                  })),
                  disabled:
                    this.readonly ||
                    this.elevage.reglement.intitule !== 'Prélèvement',
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'containerChoice',
                type: TcFormlyComponent.TcSmartSelect,
                hide: !this.allowContainerSelection,
                templateOptions: {
                  appearance: 'outline',
                  options: this.containerOptions,
                  disabled: this.readonly,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'adresseLivraison',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  appearance: 'outline',
                  storeKey: `${this.storeKey}.filter.adresseLivraison`,
                  options: this.elevage.site
                    ?.map((site) => ({
                      label: `${site.intitule}: ${
                        site.adresse ? site.adresse : ''
                      } ${site.codePostal ? site.codePostal : ''} ${
                        site.ville ? site.ville : ''
                      }`,
                      value: site.numeroInterne,
                    }))
                    ?.filter(
                      (adresse, index, self) =>
                        adresse &&
                        self.findIndex(
                          (option) => option.value === adresse.value
                        ) === index
                    ),
                  disabled: this.readonly,
                },
                smColSpan: 3,
                lgColSpan: 3,
                xlColSpan: 3,
                xxlColSpan: 3,
              }),
            ],
            colSpan: 12,
          }),
        ],
      }),
    ];

    return formFieldConfigs;
  }

  checkArticleDAS() {
    return new Promise(async (resolve) => {
      if (this.orderDocumentView?.ligne) {
        const referenceFilter = this.orderDocumentView.ligne.map(
          (commande) => commande.article.reference
        );
        const { data: articles } = await this.articleDataService.getData(
          0,
          null,
          {
            filters: [
              {
                key: 'reference',
                value: referenceFilter.join(','),
                filterType: FilterTypesEnum.In,
              },
              {
                key: 'DAS.intitule',
                value: DAS.XRCrea,
                filterType: FilterTypesEnum.Equal,
              },
            ],
          }
        );
        this.allowContainerSelection = !!articles.length;
      }
      resolve(0);
    });
  }

  async isChanged(): Promise<boolean> {
    const isFormChanged = await this.store$
      .select(getTcSmartFormIsChangedState(this.storeKey))
      .pipe(first())
      .toPromise();

    return this.ignoreConfirmation || this.readonly
      ? false
      : this.isUpdateItemActionTriggered || isFormChanged;
  }

  enableValidateButton() {
    const classes = ['commande-validate-button', 'encaissement-button'];

    for (const className of classes) {
      const elements = this.documentDOM.querySelectorAll(`.${className}`);
      if (elements?.length > 0) {
        elements.forEach((element) => {
          if (this.readonly) {
            element?.classList.add('btn-disabled');
          } else {
            element?.classList.remove('btn-disabled');
          }
        });
      }
    }
  }
}
