import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  TcGridCellComponent,
  TcGridReadyEvent,
  TcTranslateService,
  TcValidatorType,
} from '@tc/core';
import { DataType } from 'breeze-client';
import {
  clearMovement,
  removeArticleMovementLine,
  saveMovement,
  upsertArticleMovement,
} from '../../../store/stock.actions';
import { Subscription } from 'rxjs';
import {
  ArticleMovementLine,
  ArticlesMovement,
} from '../../../store/stock.state';
import { getMovement } from '../../../store/stock.selectors';
import * as R from 'ramda';
import { TcSmartButton } from '@tc/buttons';
import { Router } from '@angular/router';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';

@Component({
  selector: 'app-xpert-deposit-detail',
  templateUrl: './xpert-deposit-detail.component.html',
  styleUrls: ['./xpert-deposit-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpertDepositDetailComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'xpert-deposit-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];
  subscriptions: Subscription = new Subscription();
  movement: ArticlesMovement;
  private refresh: () => void;
  private canRefresh = true;

  buttonsList: TcSmartButton[] = [
    {
      label: 'validate-xpert-deposit',
      ionIcon: 'checkmark-outline',
      class: `mat-raised-button btn-primary`,
      smartStateKey: 'stock',
      action: saveMovement,
      actionPayload: {
        storeKey: this.storeKey,
      },
    },
  ];

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService,
    private readonly router: Router
  ) {
    super(store$);
  }

  ngOnDestroy(): void {
    this.store$.dispatch(clearMovement());
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    // Grid configuration
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'xpert-deposit-grid',
      gridOptions: {},
      columnNumberPerDevice: {
        extraSmallDevice: 8,
        smallDevice: 8,
        mediumDevice: 8,
        largeDevice: 8,
        extraLargeDevice: 8,
        extraExtraLargeDevice: 8,
      },
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'XpertDepositDetail',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Article',
          data: this.getArticleLines.bind(this),
          breezeStructuralTypeExtension: {
            reference: DataType.String,
            numeroLot: DataType.String,
            description: DataType.String,
            quantiteEnStock: DataType.Int32,
            quantite: DataType.Int32,
          },
        },
      },
      columns: [
        {
          field: 'reference',
          maxWidth: 150,
          comparator: gridComparatorForString,
        },
        {
          field: 'description',
          minWidth: 280,
          cellRenderer: (params) =>
            `<span title="${params?.data?.description}">${params?.data?.description}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: '_id',
          headerValueGetter: () => this.translate.instant('lot-serie'),
          minWidth: 240,
          comparator: gridComparatorForString,
        },
        {
          field: 'quantiteEnStock',
          maxWidth: 170,
          cellClass: `quantityCell text-align-right`,
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'quantite',
          headerValueGetter: () => this.translate.instant('to-transfert'),
          maxWidth: 280,
          cellClass: `quantityCell text-align-right`,
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
          cellRenderer: TcGridCellComponent.MatInputEditor,
          cellRendererParams: {
            type: 'text',
            smartState: {
              storeKey: this.storeKey,
              field: 'quantite',
            },
            mask: '0*',
            selectTextOnClick: true,
            beforeChange: (value) => {
              // To avoid empty values and leading zeros
              if (value === '') {
                return 0;
              }
              const result = parseInt(value, 10);
              return isNaN(result) || result < 0 ? 0 : result;
            },
            onValueChange: (value, data) => {
              if (value <= data.quantiteEnStock) {
                // Map Article to article movement line
                const articleMovementLine: ArticleMovementLine = {
                  _id: data._id,
                  reference: data.reference,
                  lotId: data.lotId ?? null,
                  description: data.description,
                  quantiteEnStock: data.quantiteEnStock,
                  quantite: data.quantite,
                  numeroLot: data.numeroLot ?? null,
                  cbMarq: data.cbMarq,
                  complement: data.complement,
                  dateFabrication: data.dateFabrication,
                  datePeremention: data.datePeremention,
                };
                this.canRefresh = false;
                this.store$.dispatch(
                  upsertArticleMovement({ data: articleMovementLine })
                );
                setTimeout(() => {
                  // Restore capacity to reload after store finished is job
                  this.canRefresh = true;
                });
              }
            },
            validators: [
              (value, data) => {
                if (value > +data.quantiteEnStock) {
                  return {
                    type: TcValidatorType.Max,
                    message: this.translate.instant(
                      'quantity-exceed-quantity-in-stock'
                    ),
                  };
                }
                return null;
              },
            ],
          },
        },
        {
          field: 'actions',
          sortable: false,
          width: 120,
          headerClass: 'text-align-center',
          cellClass: `text-align-center`,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'trash-outline',
                tooltip: 'trash-tooltip',
                action: removeArticleMovementLine,
                actionPayload: {
                  storeKey: this.storeKey,
                },
              },
            ],
          },
        },
      ],
    };

    // Subscription on movement to get current departure
    const movementSubscription = this.store$
      .select(getMovement)
      .subscribe((movement) => {
        this.movement = movement;
        // Refresh the grid data
        if (
          this.canRefresh &&
          this.refresh !== undefined &&
          this.movement.articles.length > 0
        ) {
          this.refresh();
        }
      });
    this.subscriptions.add(movementSubscription);

    super.ngOnInit();
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
  }

  public getArticleLines() {
    // Apply always the same order to avoid the grid to display articles randomly (will not impact the grid sort)
    const articles = R.clone(this.movement.articles);
    if (articles.length > 0) {
      articles.sort((a, b) => a._id.localeCompare(b._id));
      return articles;
    }
    return [];
  }
}
