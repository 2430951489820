import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ITcDataService,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  TcSmartFormConfig,
} from '@tc/abstract';
import { navigate, TcSmartGridComponent } from '@tc/advanced-components';
import { TcSmartButton } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
  TcGridReadyEvent,
  TcTranslateService,
  TcValidatorType,
} from '@tc/core';
import { PinnedRowPosition } from '@tc/abstract';
import { merge, Observable, Subscription } from 'rxjs';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import * as R from 'ramda';
import { DataType } from 'breeze-client';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  TcDataService,
  loadTcDataSuccess,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { distinctUntilChanged, filter, first, take } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { getExceededQuantitiesState } from '../../../../livraison/store/livraison.selectors';
import {
  getTcSmartFormIsChangedState,
  initTcSmartForm,
  setTcSmartFormModel,
  updateTcSmartFormCurrentModel,
} from '@tc/smart-form';
import { PaymentMode } from '../../../../../typings/payment-mode.enum';
import {
  removeDeliveryLine,
  saveDelivery,
  setExceededQuantitiesState,
  updateDeliveryDocument,
  updateDeliveryLine,
} from '../../../../livraison/store/livraison.actions';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { Actions, ofType } from '@ngrx/effects';
import { getCurrentDocument } from '../../../../article/store/catalog-article.selectors';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { DocumentService } from '../../../../../services/document.service';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import {
  decimalsForXpertMobile,
  emptyDecimalFormatForXpertMobile,
} from '../../../../../shared/decimals';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { TcLocalStorageService } from '@tc/local-storage';
import { LocalStorageKeys } from '../../../../../typings/local-storage-keys.enum';
import { StockService } from '../../../../stock/services/stock.services';
import { MatDialog } from '@angular/material/dialog';
import { EncaissementPopupComponent } from '../../../../encaissement/components/smart/encaissement-popup/encaissement-popup.component';

@Component({
  selector: 'app-livraison-detail-grid',
  templateUrl: './livraison-detail-grid.component.html',
  styleUrls: ['./livraison-detail-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LivraisonDetailGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'livraison-detail-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  dataStore$: Observable<NgRxTcDataState>;

  elevage: Elevage;
  buttonsList: TcSmartButton[];
  deliveryDocumentView: ElevageDocument | null;
  subscription = new Subscription();

  exceededQuantitiesArticleIds: string[] = [];

  private isUpdateItemActionTriggered = false;
  private ignoreConfirmation = false;
  private refresh: () => void;
  private refreshLaunched = false;
  private currentVehicleCode: string;
  private total = 0;

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'depot',
    }
  );

  constructor(
    store$: Store<any>,
    public readonly translate: TcTranslateService,
    private readonly actions$: Actions,
    private readonly docService: DocumentService,
    private readonly dataService: TcDataService,
    private readonly localStorage: TcLocalStorageService,
    private readonly stockService: StockService,
    private dialog: MatDialog
  ) {
    super(store$);

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
    this.loadCurrentElevage();
    this.loadVehicleCode();
  }

  async loadVehicleCode() {
    this.currentVehicleCode = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );
  }

  async loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe(async (elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);

    const documentSubscription = this.store$
      .pipe(select(getCurrentDocument))
      .subscribe((document) => {
        this.deliveryDocumentView = document;
        this.refreshFormAndGrid();
        this.total = this.docService.getTotalPriceExclTax(
          this.deliveryDocumentView
        );
      });

    this.subscription.add(documentSubscription);
  }

  /**
   * Function to sync form and grid data with the loaded document
   */
  public refreshFormAndGrid() {
    if (this.refresh) {
      if (this.refreshLaunched === false) {
        this.refreshLaunched = true;
        this.setFormValues();
        this.refresh();
      }
    }
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
  }

  async ngOnInit() {
    this.initDeliveryHeading();

    this.buttonsList = [
      {
        label: 'livraison-detail-grid.button.back',
        ionIcon: 'arrow-back-outline',
        class: 'btn-secondary',
        action: navigate,
        actionPayload: {
          route: `/${CustomRoutes.Livraison}`,
        },
      },
      {
        label: 'validate-delivery-encaissement',
        ionIcon: 'checkmark-outline',
        class: 'mat-raised-button btn-secondary encaissement-button',
        disableStoreKey: 'quantities',
        disableSelector: getExceededQuantitiesState,
        smartStateKey: 'livraison',
        action: () => {
          const dialog = this.dialog.open(EncaissementPopupComponent, {
            data: {
              total: this.total,
            },
            width: '30em',
          });
          dialog.afterClosed().subscribe((result) => {
            if (result && result?.setInStore === true) {
              // Launch the saving process of the grid. If encaissement is present in the store, it will be saved as well.
              this.store$.dispatch(
                saveDelivery({
                  storeKey: this.storeKey,
                })
              );
            }
          });
        },
      },
      {
        label: 'livraison-detail-grid.button',
        ionIcon: 'checkmark-outline',
        class: 'mat-raised-button btn-primary',
        disableStoreKey: 'quantities',
        disableSelector: getExceededQuantitiesState,
        smartStateKey: 'livraison',
        action: saveDelivery,
        actionPayload: {
          storeKey: this.storeKey,
        },
      },
    ];

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'livraison-detail-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      columnNumberPerDevice: {
        extraSmallDevice: 9,
        smallDevice: 9,
        mediumDevice: 9,
        largeDevice: 9,
        extraLargeDevice: 9,
        extraExtraLargeDevice: 9,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageLivraisonDetail',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document.ligne',
          data: this.getDeliveryLines.bind(this),
          breezeStructuralTypeExtension: {
            numeroLot: DataType.String,
            dateLivraison: DataType.String,
            adresseLivraison: DataType.String,
            prixUnitaireRemise: DataType.Double,
            prixUnitaireTotal: DataType.Double,
            availableQuantity: DataType.Double,
            conditionnementXpert: DataType.String,
          },
        },
      },
      pinnedRowsConfig: [
        {
          rowId: 'total-row1',
          cssClass: 'row-total',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('total'),
              cellClass: 'nom-cell',
              colSpan: 6,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService.getTotalPriceExclTax(
                  this.deliveryDocumentView
                )}€`,
              cellClass: 'text-align-right',
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
      ],
      columns: [
        {
          field: 'article.reference',
          maxWidth: 270,
          sort: 'asc',
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.article?.reference
              ? valueA?.article?.reference
              : '';
            const valueBCleaned = valueB?.article?.reference
              ? valueB?.article?.reference
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'libelle',
          minWidth: 250,
          cellRenderer: (params) =>
            `<span title="${params?.data?.libelle}">${params?.data?.libelle}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: 'conditionnementXpert',
          maxWidth: 120,
          comparator: gridComparatorForString,
        },
        {
          field: 'numeroLot',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          minWidth: 90,
          maxWidth: 90,
          comparator: gridComparatorForString,
        },
        {
          field: 'quantite',
          minWidth: 200,
          cellClass: 'quantityCell text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
          cellRenderer: TcGridCellComponent.MatInputEditor,
          cellRendererParams: {
            type: 'text',
            smartState: {
              storeKey: this.storeKey,
              field: 'quantite',
            },
            mask: '0*',
            selectTextOnClick: true,
            beforeChange: (value) => {
              // To avoid empty values and leading zeros
              if (value === '') {
                return 0;
              }
              const result = parseInt(value, 10);
              return isNaN(result) || result < 0 ? 0 : result;
            },
            onValueChange: (value, data) => {
              // Check if quantity is ok before updating the store
              if (this.exceededQuantitiesArticleIds.length > 0) {
                return;
              }
              this.store$.dispatch(
                updateDeliveryLine({ storeKey: this.storeKey, rowData: data })
              );
            },
            onValueChangeDelayInMillis: 1000,
            validators: [
              (value, data) => {
                if (value > +data.availableQuantity) {
                  this.exceededQuantitiesArticleIds.push(data._id);
                  this.store$.dispatch(
                    setExceededQuantitiesState({ exceeded: true })
                  );
                  return {
                    type: TcValidatorType.Max,
                    message: `${this.translate.instant(
                      'livraison-grid.error.exceedQuantity'
                    )}: ${data.availableQuantity}`,
                  };
                }

                const index = this.exceededQuantitiesArticleIds.indexOf(
                  data._id
                );
                if (index >= 0) {
                  this.exceededQuantitiesArticleIds.splice(index, 1);
                }

                if (this.exceededQuantitiesArticleIds.length === 0) {
                  this.store$.dispatch(
                    setExceededQuantitiesState({ exceeded: false })
                  );
                }
                return null;
              },
            ],
          },
        },
        {
          field: 'prixUnitaire',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaire
                ? `${params.data.prixUnitaire.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          maxWidth: 270,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaireRemise',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaireRemise
                ? `${params.data.prixUnitaireRemise.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          maxWidth: 270,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaireTotal',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaireTotal
                ? `${params.data.prixUnitaireTotal.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          maxWidth: 270,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'actions',
          sortable: false,
          width: 120,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'trash-outline',
                tooltip: 'trash-tooltip',
                action: removeDeliveryLine,
                actionPayload: {
                  storeKey: this.storeKey,
                },
              },
            ],
          },
          pinnedRowCellRenderer: () => '',
        },
      ],
    };

    super.ngOnInit();

    this.subscription.add(
      this.actions$.pipe(ofType(updateItemSuccess), take(1)).subscribe(() => {
        this.isUpdateItemActionTriggered = true;
      })
    );

    this.subscription.add(
      merge(this.actions$.pipe(ofType(updateItem)))
        .pipe(take(1))
        .subscribe(() => {
          this.ignoreConfirmation = true;
        })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(loadTcDataSuccess)).subscribe(() => {
        this.refreshLaunched = false;
      })
    );

    this.subscription.add(
      this.actions$
        .pipe(ofType(updateTcSmartFormCurrentModel))
        .subscribe(() => {
          this.store$.dispatch(
            updateDeliveryDocument({ storeKey: this.storeKey })
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initDeliveryHeading() {
    const formConfig: TcSmartFormConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.storeKey,
      autoSubmit: false,
      fieldConfigs: this.getDeliveryHeadingFields(),
    };

    this.store$.dispatch(
      initTcSmartForm({
        storeKey: formConfig.storeKey,
        config: formConfig,
      })
    );

    this.setFormValues();
  }

  public setFormValues() {
    let noteDeReglement;

    if (this.elevage.reglement.intitule !== 'Prélèvement') {
      noteDeReglement = PaymentMode.Paiement1X;
    }

    const formDefaultValue = {
      datePiece:
        this.deliveryDocumentView?.date ||
        new Date().toISOString().split('T')[0],
      dateLivraison:
        this.deliveryDocumentView?.dateLivraison ||
        new Date().toISOString().split('T')[0],
      adresseLivraison: this.deliveryDocumentView?.siteLivraison?._id
        ? parseFloat(this.deliveryDocumentView?.siteLivraison?._id)
        : undefined,
      numeroPiece: this.deliveryDocumentView?.numeroPiece,
      noteDeReglement:
        noteDeReglement ||
        this.deliveryDocumentView?.noteDeReglement ||
        PaymentMode.Paiement1X,
    };

    this.store$.dispatch(
      setTcSmartFormModel({
        storeKey: this.storeKey,
        model: formDefaultValue,
      })
    );
  }

  private getDeliveryHeadingFields() {
    const formFieldConfigs: FormlyFieldConfig[] = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'dateLivraison',
                className: 'date-picker semi-disabled',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  disabled: true,
                  matIcon: 'calendar_today',
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'adresseLivraison',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  appearance: 'outline',
                  storeKey: `${this.storeKey}.filter.adresseLivraison`,
                  options: this.elevage.site
                    ?.map((site) => ({
                      label: `${site.intitule}: ${site.adresse} ${site.codePostal} ${site.ville}`,
                      value: site.numeroInterne,
                    }))
                    ?.filter(
                      (adresse, index, self) =>
                        adresse &&
                        self.findIndex(
                          (option) => option.value === adresse.value
                        ) === index
                    ),
                },
                smColSpan: 4,
                lgColSpan: 4,
                xlColSpan: 4,
                xxlColSpan: 4,
              }),
              formlyControl({
                key: 'datePiece',
                className: 'date-picker semi-disabled',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  disabled: true,
                  matIcon: 'calendar_today',
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'numeroPiece',
                className: 'semi-disabled',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  label: 'numeroPiece',
                  type: 'text',
                  appearance: 'outline',
                  disabled: true,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'noteDeReglement',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  appearance: 'outline',
                  options: Object.values(PaymentMode).map((paymentMode) => ({
                    value: paymentMode,
                    label: paymentMode,
                  })),
                  disabled: this.elevage.reglement.intitule !== 'Prélèvement',
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
            ],
          }),
        ],
      }),
    ];

    return formFieldConfigs;
  }

  public async getDeliveryLines(): Promise<any[]> {
    const toDisplay = [];
    const lignes = R.clone(this.deliveryDocumentView?.ligne ?? []);
    if (
      this.deliveryDocumentView !== null &&
      this.deliveryDocumentView?.ligne?.length > 0
    ) {
      const articleFilter = lignes
        .map((ligne) => ligne.article.reference)
        .join(',');

      const { data: articles } = await this.articleDataService.getData(
        0,
        null,
        {
          filters: [
            {
              key: 'reference',
              value: articleFilter,
              filterType: FilterTypesEnum.In,
            },
          ],
        }
      );
      lignes.forEach((ligne: DocumentLigne) => {
        const updatedLigne = R.clone(ligne);
        this.docService.setLignePricesForGrid(updatedLigne);
        const fullArticle = articles.find(
          (article) => article.reference === updatedLigne.article.reference
        );

        updatedLigne.availableQuantity =
          this.stockService.getArticleRemainingQuantity(
            fullArticle,
            ligne.temporaryNumeroLot ?? null,
            null,
            this.currentVehicleCode
          );

        updatedLigne.libelle = fullArticle?.intitule;
        updatedLigne.numeroLot = ligne.temporaryNumeroLot;
        toDisplay.push(updatedLigne);
      });
    }

    // Only declared as a promise to comply to the interface. Returns the data directly.
    return toDisplay;
  }

  async isChanged(): Promise<boolean> {
    const isFormChanged = await this.store$
      .select(getTcSmartFormIsChangedState(this.storeKey))
      .pipe(first())
      .toPromise();

    return this.ignoreConfirmation
      ? false
      : this.isUpdateItemActionTriggered || isFormChanged;
  }
}
