import { selectValueByKey } from '@tc/store';
import { NgRxTcDataState, TcDataService } from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  getTcGridSelection,
  NgRxTcGridState,
} from '@tc/core';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import { getCurrentElevage } from '../../elevage/store/elevage.selectors';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { DiscountType } from '../../../typings/discount-type.enum';
import { createOrderFromXrOptima } from './xroptima.actions';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { HistoriqueAchatsAppros } from '../../elevage/interfaces/elevage.interface';
import { setCurrentDocument } from '../../article/store/catalog-article.actions';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';
import { getCurrentDocument } from '../../article/store/catalog-article.selectors';
import { XpertService } from '../../../services/xpert.service';
import { DocumentService } from '../../../services/document.service';
import { DocumentDomaine, DocumentType } from '../../../typings/document.enum';
import { DocumentLigne } from '../../elevage/interfaces/document-ligne.interface';
import { ArticleOrigin } from '../../commande/store/commande.payloads';

@Injectable()
export class XrOptimaEffects {
  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcDataState>;

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'reference',
    }
  );

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly dataService: TcDataService,
    private readonly xpertService: XpertService,
    private readonly docService: DocumentService
  ) {
    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  createOrderFromXrOptima$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createOrderFromXrOptima),
        tap(async (payload) => {
          const { storeKey, orderDocNum } = payload;

          const selectedLines: HistoriqueAchatsAppros[] = R.clone(
            await selectValueByKey(
              getTcGridSelection,
              this.gridStore$,
              storeKey
            )
          );

          const elevage = await this.store$
            .select(getCurrentElevage)
            .pipe(take(1))
            .toPromise();

          const xpert = await this.xpertService.getConnectedXpert();

          const prescripteur = {
            _id: xpert.idXpert,
            nom: xpert.nom,
            prenom: xpert.prenom,
          };

          const siteLivraison = elevage.site?.find((site) => site.estPrincipal);

          // Check if you have an document already in the store. If yes, use it as basis.
          let document: ElevageDocument | null = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );

          // Security : if the current document is not of the right type, create it from scratch
          document = document?.type === DocumentType.VenteBC ? document : null;

          let numXpertMobile = document?.numXpertMobile;
          let numeroPiece = document?.numeroPiece;

          // No document in the store, create it
          if (document === null) {
            numXpertMobile = await this.docService.generateNewNumXpertMobile(
              xpert
            );
            numeroPiece = (
              (await this.docService.getLastTempNumeroPiece()) + 1
            ).toString();

            document = {
              numXpertMobile: numXpertMobile,
              domaine: DocumentDomaine.Vente,
              type: DocumentType.VenteBC,
              prescripteur: prescripteur,
              createdOn: new Date().toISOString(),
              date: new Date().toISOString().split('T')[0],
              dateLivraison: null,
              numeroPiece: numeroPiece.toString(),
              ligne: [],
              noteDeReglement: null,
              montant: null,
              totalTTC: null,
              dateCommande: new Date().toISOString().split('T')[0],
              echeance: null,
              siteLivraison: siteLivraison
                ? {
                    _id: siteLivraison.numeroInterne.toString(),
                    intitule: siteLivraison.intitule as string,
                  }
                : null,
            };
          }

          for (const XrOptimaRow of selectedLines) {
            // Reload article from article collection based on the reference found in the XrOptima history
            const articleFromDb = await this.articleDataService.getData(
              0,
              null,
              {
                filters: [
                  {
                    key: 'reference',
                    value: XrOptimaRow.referenceArticle,
                    filterType: FilterTypesEnum.Equal,
                  },
                ],
              }
            );

            // Instanciate a new article object and modify it for compiling the order lines
            const articleRow = R.clone(articleFromDb.data[0]);
            if (articleRow === undefined) {
              throw Error(
                'Could not find article with reference ' +
                  XrOptimaRow.referenceArticle
              );
            }
            // Search for document ligne in the document
            let ligne: DocumentLigne = document.ligne.find(
              (item) => item.article.reference === XrOptimaRow.referenceArticle
            );
            if (!ligne) {
              ligne = {
                numXpertMobile: numXpertMobile,
                domaine: DocumentDomaine.Vente,
                uniteVente: articleRow.uniteVente,
                numeroPiece: numeroPiece,
                numeroInterne: null,
                numeroPieceBonLivraisonOrigine: null,
                type: DocumentType.VenteBC,
                quantite: 0,
                taxe: articleRow.taxe.taux,
                codeTaxe: articleRow.taxe.code,
                article: { reference: articleRow.reference },
                libelle: articleRow.intitule,
                typeRemise: articleRow.typeRemise
                  ? (+articleRow.typeRemise as DiscountType)
                  : null,
                dateLivraison: null,
                datePieceDevisOrigine: null,
                montantRemise:
                  articleRow.tarif?.find(
                    (tarif) => tarif.elevage.numero === elevage.numero
                  )?.remiseGenerale ?? 0,
                prixUnitaire:
                  articleRow.tarif?.find(
                    (tarif) => tarif.elevage.numero === elevage.numero
                  )?.prixVente ?? 0,
                numeroLot: null,
                dateFabrication: null,
                datePeremention: null,
                complement: null,
                cbMarq: null,
              };

              // Add the article to the list if he was not already present
              document.ligne.push(ligne);
            }
          }

          // Send the document to the store
          this.store$.dispatch(
            setCurrentDocument({
              document: document,
              articleOrigin: ArticleOrigin.XrOptimaPurchaseHistory,
            })
          );

          this.store$.dispatch(
            navigate({
              route: `/${CustomRoutes.DetailCommandesList}`,
              queryParams: { fromXrOptima: true, orderDocNum },
              storeKey: storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );
}
