import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';

/**
 * Service to send an email using the mailto protocol
 */
@Injectable({
  providedIn: 'root',
})
export class TcMailToService extends TcService {
  constructor() {
    super();
  }

  /**
   * Send an email using the mailto protocol
   * @param emailAddress string Email address to send the email to
   * @param subject string Subject of the email
   * @param body string Body of the email
   */
  public send(emailAddress: string, subject: string, body: string): void {
    const encodedBody = encodeURIComponent(body.replace(/\n/g, '%0D%0A'));

    let mailToText = `mailto:${emailAddress}?subject=${subject}&body=${encodedBody}`;

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = mailToText;
    a.target = '_blank'; // Open in a new tab
    a.style.display = 'none'; // Hide the element

    // Append to the DOM and trigger the click event
    document.body.appendChild(a);

    setTimeout(() => {
      console.log('Sending email: ', mailToText);

      //window.location.href = mailToText;
      a.click();

      // Clean up the DOM by removing the anchor
      document.body.removeChild(a);
    }, 1000);
  }
}
