import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  MaterialColor,
  ListOrder,
  FilterTypesEnum,
} from '@tc/abstract';
import { TcGridCellComponent, TcTranslateService } from '@tc/core';

import { navigate, TcSmartGridComponent } from '@tc/advanced-components';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { Subscription } from 'rxjs';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import * as R from 'ramda';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import {
  DocumentDomaine,
  DocumentType,
} from '../../../../../typings/document.enum';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { DataType } from 'breeze-client';
import { decimalsForXpertMobile } from '../../../../../shared/decimals';
import { CommandeGridDocument } from '../../../interfaces/commande-grid-document.interface';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { DocumentService } from 'apps/frontend/src/app/custom/services/document.service';

@Component({
  selector: 'app-commandes-grid',
  templateUrl: './commandes-grid.component.html',
  styleUrls: ['./commandes-grid.component.scss'],
})
export class CommandesGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'commandes-grid';

  subscription = new Subscription();
  elevage: Elevage;

  constructor(
    store$: Store<any>,
    private readonly translate: TcTranslateService,
    private readonly docService: DocumentService
  ) {
    super(store$);

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);
  }

  private formatElevageDocuments(
    elevageDocuments: ElevageDocument[]
  ): CommandeGridDocument[] {
    const commandeGridDocuments: CommandeGridDocument[] = [];
    elevageDocuments.forEach((document) => {
      const newDocument: CommandeGridDocument = R.clone(document);
      newDocument.dateCommande = newDocument.date; //For version A, the date and order date are the same

      let montant = 0;
      newDocument.ligne?.forEach((ligne) => {
        montant += (ligne.prixUnitaire ?? 0) * (ligne.quantite ?? 0);
      });

      newDocument.montant = montant;

      const isPartialDelivery =
        elevageDocuments.findIndex(
          (doc) =>
            [
              DocumentType.VenteFacture,
              DocumentType.VenteBL,
              DocumentType.VenteFactureCompta,
            ].includes(doc.type) &&
            doc.ligne.some(
              (ligne) =>
                ligne.numeroPieceBonCommandeOrigine === newDocument.numeroPiece
            )
        ) >= 0 ||
        (newDocument.type === DocumentType.VenteBC &&
          newDocument.ligne.some(
            (ligne) =>
              ligne.type === DocumentType.VenteBC &&
              ligne.numeroPiece === newDocument.numeroPiece
          ));

      newDocument.delivery = isPartialDelivery
        ? this.translate.instant('partialOrder')
        : newDocument.dateLivraison;

      commandeGridDocuments.push(newDocument);
    });

    return commandeGridDocuments;
  }

  async ngOnInit() {
    const elevageDocuments: CommandeGridDocument[] =
      this.formatElevageDocuments(this.elevage.documents);

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'commandes-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageCommandes',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document',
          data: elevageDocuments,
          breezeStructuralTypeExtension: {
            noteDeReglement: DataType.String,
            description: DataType.String,
            dateCommande: DataType.String,
            montant: DataType.Double,
            delivery: DataType.String,
          },
        },
        filter: {
          filters: [
            {
              key: 'domaine',
              value: DocumentDomaine.Vente.toString(),
              filterType: FilterTypesEnum.Equal,
            },
            {
              key: 'type',
              value: DocumentType.VenteBC.toString(),
              filterType: FilterTypesEnum.Equal,
            },
          ],
        },
      },
      columns: [
        {
          field: 'numeroPiece',
          minWidth: 150,
          maxWidth: 150,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          comparator: gridComparatorForString,
        },
        {
          field: 'createdOn',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          maxWidth: 120,
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
          comparator: gridComparatorForString,
        },
        {
          field: 'dateCommande',
          sort: ListOrder.Desc,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          width: 150,
          suppressSizeToFit: true,
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
          comparator: gridComparatorForString,
        },
        {
          field: 'prescripteur',
          minWidth: 145,
          maxWidth: 150,
          valueFormatter: (params) => {
            return `${params.value?.nom ? params.value.nom + ' ' : ''}${
              params.value?.prenom ?? ''
            }`;
          },
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (prescripteur1, prescripteur2) => {
            const fullName1 = `${
              prescripteur1?.nom ? prescripteur1.nom + ' ' : ''
            }${prescripteur1?.prenom ?? ''}`;
            const fullName2 = `${
              prescripteur2?.nom ? prescripteur2.nom + ' ' : ''
            }${prescripteur2?.prenom ?? ''}`;

            return fullName1.localeCompare(fullName2);
          },
        },
        {
          field: 'noteDeReglement',
          cellClass: 'text-align-left',
          comparator: gridComparatorForString,
        },
        {
          field: 'description',
          comparator: gridComparatorForString,
        },
        {
          field: 'delivery',
          maxWidth: 150,
          valueFormatter: (params) => {
            if (!params.value) return '';

            if (isNaN(Date.parse(params.value))) {
              return params.value;
            }

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
          comparator: gridComparatorForString,
        },
        {
          field: 'total',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${this.docService
              .getTotalPriceWithDiscount(params.data)
              .toLocaleString(undefined, {
                minimumFractionDigits: decimalsForXpertMobile,
              })} €`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'actions',
          sortable: false,
          minWidth: 100,
          width: 120,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.LinkRenderer,
          valueGetter: (params) => [' '],
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.DetailCommandesList}`,
                  queryParams: [
                    {
                      paramName: 'orderDocNum',
                      dataPropertyName: 'numeroPiece',
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
      columnNumberPerDevice: {
        extraSmallDevice: 9,
        smallDevice: 9,
        mediumDevice: 9,
        largeDevice: 9,
        extraLargeDevice: 9,
        extraExtraLargeDevice: 9,
      },
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
