import { Injectable } from '@angular/core';
import { TcNotificationService } from '../services/tc-notification.service';
import { TcTranslateService } from '../services/tc-translate.service';
import { TcService } from '@tc/abstract';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';

@Injectable({
  providedIn: 'root',
})
export class TcCsvService extends TcService {
  constructor(
    private notification: TcNotificationService,
    private translation: TcTranslateService
  ) {
    super();
  }

  /**
   * Convert json string to csv file and auto download it when it is ready. Mind that this method is asynchronous, file conception will take time to process.
   * @param json string JSON data as string. Use JSON.stringify() on your array before calling download. json2csv dependancy is expecting a string.
   * @param filename string Filename for download
   * @param columns Array<string> (optionnal) Array of expected columns names
   */
  public download(
    json: string,
    filename: string,
    columns?: Array<string>
  ): void {
    if (this.IsJson(json) === false) {
      throw Error('Please provide a valid JSON string.');
    }
    this.notification.info(
      this.translation.instant('tc-csv-service.waitingForFile')
    );
    // The parser used is the async one because it is the one recommanded for large dataset. The procedural one can mess up with memory if file is too big.
    const { AsyncParser } = json2csv;
    // Here, you can parameter json2csv export and changing delimiters, end of line, escaping characters... For now, we will setup it for the most common usages.
    const opts = {
      columns,
      delimiter: ';',
      header: true,
      eol: '\r\n',
      withBOM: true,
    };
    const asyncParser = new AsyncParser(opts);
    let csv = '';

    // Calling the processor to compile the file and to download it when it's ready or using TcNotificationService if something goes wrong.
    asyncParser.processor
      .on('data', (chunk) => (csv += chunk.toString()))
      .on('end', () => this.serve(filename, csv))
      .on('error', (error: string) => this.notification.error(error));

    asyncParser.input.push(json); // Data to convert as a string
    asyncParser.input.push(null); // Sending `null` to a stream signal that no more data is expected and ends it
  }

  /**
   * Convert json string to csv file. Mind that this method is asynchronous, file conception will take time to process.
   * @param json string JSON data as string. Use JSON.stringify() on your array before calling download. json2csv dependancy is expecting a string.
   * @param columns Array<string> (optionnal) Array of expected columns names
   */
  public getCSV(json: string, columns?: Array<string>): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.IsJson(json) === false) {
        throw Error('Please provide a valid JSON string.');
      }
      // The parser used is the async one because it is the one recommanded for large dataset. The procedural one can mess up with memory if file is too big.
      const { AsyncParser } = json2csv;
      // Here, you can parameter json2csv export and changing delimiters, end of line, escaping characters... For now, we will setup it for the most common usages.
      const opts = {
        columns,
        delimiter: ';',
        header: true,
        eol: '\r\n',
        withBOM: true,
      };
      const asyncParser = new AsyncParser(opts);
      let csv = '';

      // Calling the processor to compile the file and to download it when it's ready or using TcNotificationService if something goes wrong.
      asyncParser.processor
        .on('data', (chunk) => (csv += chunk.toString()))
        .on('end', () => resolve(csv))
        .on('error', (error: string) => reject(error));

      asyncParser.input.push(json); // Data to convert as a string
      asyncParser.input.push(null); // Sending `null` to a stream signal that no more data is expected and ends it
    });
  }

  /**
   * Auto download CSV file when it's ready
   * @param filename CSV filename
   * @param csv string CSV file content
   */
  public serve(filename: string, csv: string, showNotification = true): void {
    if (showNotification) {
      this.notification.success(
        this.translation.instant('tc-csv-service.fileReady')
      );
    }
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, filename);
  }

  /**
   * Check that a string is a valid JSON data
   */
  private IsJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
