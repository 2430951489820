export interface LotBase {
  numeroLot: string | null;
  datePeremention: string | null;
  complement: string | null;
  dateFabrication: string | null;
  cbMarq: number | null;
  lotId?: string;
}

export function isSameLot(lot1: LotBase, lot2: LotBase): boolean {
  return (
    lot1.numeroLot === lot2.numeroLot &&
    lot1.datePeremention === lot2.datePeremention &&
    lot1.complement === lot2.complement &&
    lot1.dateFabrication === lot2.dateFabrication &&
    lot1.cbMarq === lot2.cbMarq
  );
}

export function setLotId(lots: LotBase[]) {
  for (const lot of lots) {
    const datePeremention =
      lot.datePeremention === '1753-01-01'
        ? null
        : lot.datePeremention
        ? new Date(lot.datePeremention)
        : null;
    const dateFabrication = lot.dateFabrication
      ? new Date(lot.dateFabrication)
      : null;

    const formattedDatePeremention = formatDate(datePeremention);
    const formattedDateFabrication = formatDate(dateFabrication);

    lot.lotId = `${lot.numeroLot}`;
    lot.lotId += formattedDatePeremention ? `-${formattedDatePeremention}` : '';
    lot.lotId += lot.complement ? `-${lot.complement}` : '';
    lot.lotId += formattedDateFabrication ? `-${formattedDateFabrication}` : '';
  }

  // Group lots by label to handle duplicates
  const lotsBylotId = lots.reduce((groups, lot) => {
    if (!groups[lot.lotId ?? '']) {
      groups[lot.lotId ?? ''] = [];
    }
    groups[lot.lotId ?? ''].push(lot);
    return groups;
  }, {});

  // Handle duplicate labels by appending cbMarq to all lots with the same label
  Object.values(lotsBylotId).forEach((lots: any[]) => {
    if (lots.length > 1) {
      lots.forEach((lot) => {
        lot.lotId += lot.cbMarq ? `-${lot.cbMarq}` : '';
      });
    }
  });
}

function formatDate(date: Date | null): string | null {
  if (!date) {
    return null;
  }
  return `${String(date.getDate()).padStart(2, '0')}/${String(
    date.getMonth() + 1
  ).padStart(2, '0')}/${date.getFullYear()}`;
}
