import { selectValueByKey, TcSpinnerService } from '@tc/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  deleteItemSuccess,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NgRxTcGridState, TcTranslateService } from '@tc/core';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import {
  editOrderArticles,
  recalculatePrice,
  removeOrderLine,
  saveOrder,
  updateOrderDocument,
  updateOrderLine,
} from './commande.actions';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { getCurrentElevage } from '../../elevage/store/elevage.selectors';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';
import { DocumentService } from '../../../services/document.service';
import * as R from 'ramda';
import { ElevageService } from '../../../services/elevage.service';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormCurrentModel,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import {
  redirectToArticleOrigin,
  setCurrentDocument,
} from '../../article/store/catalog-article.actions';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { ArticleOrigin } from './commande.payloads';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from '../../article/store/catalog-article.selectors';
import moment from 'moment';
import { PriceService } from '../../../services/price.service';
import { ArticleService } from '../../../services/article.service';
import {
  saveCurrentEncaissement,
  setCurrentEncaissement,
} from '../../encaissement/store/encaissement.actions';
import { getCurrentEncaissement } from '../../encaissement/store/encaissement.selectors';
import { Encaissement } from '../../encaissement/store/encaissement.state';

@Injectable()
export class CommandeEffects {
  dataStore$: Observable<NgRxTcGridState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly docService: DocumentService,
    private readonly elevageService: ElevageService,
    private readonly articleService: ArticleService,
    private readonly priceService: PriceService,
    private readonly spinner: TcSpinnerService,
    private readonly dialog: MatDialog,
    private readonly translateService: TcTranslateService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  editOrderArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editOrderArticles),
        tap(async (payload) => {
          const { storeKey, orderDocNum } = payload;

          // Based on the screen that initiated the order, redirect to the right screen
          this.store$.dispatch(
            redirectToArticleOrigin({
              orderDocNum: orderDocNum,
              storeKey: storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveOrder),
        tap(async (payload) => {
          const { storeKey, orderDocNum, route } = payload;

          const action = `${storeKey} - spinner`;
          this.spinner.showSpinner(action);

          const elevage = R.clone(
            await this.store$
              .select(getCurrentElevage)
              .pipe(take(1))
              .toPromise()
          );

          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );

          // XPR-14: be sure that delivery address was selected
          if (document && !document.siteLivraison?._id) {
            this.dialog.open(TcPromptDialogComponent, {
              width: '37.5em',
              data: {
                title: this.translateService.instant('prompt.title'),
                disableTextTranslation: true,
                text: this.translateService.instant('delivery-address-error'),
                confirm: this.translateService.instant('globalLabels.close'),
                displayCancelButton: false,
                displayConfirmButton: true,
                disableClose: true,
              },
            });
            this.spinner.hideSpinner(action);
            return;
          }

          // XPR-12
          if (document && document.dateCommande && document.dateLivraison) {
            const orderDate = moment(document.dateCommande);
            const deliveryDate = moment(document.dateLivraison);

            if (deliveryDate.isBefore(orderDate)) {
              this.dialog.open(TcPromptDialogComponent, {
                width: '37.5em',
                data: {
                  title: this.translateService.instant('prompt.title'),
                  disableTextTranslation: true,
                  text: this.translateService.instant(
                    'delivery-date-is-before-order-date-error'
                  ),
                  confirm: this.translateService.instant('globalLabels.close'),
                  displayCancelButton: false,
                  displayConfirmButton: true,
                  disableClose: true,
                },
              });
              this.spinner.hideSpinner(action);
              return;
            }
          }

          // Security checks
          if (
            this.docService.securityChecksForDocument(this.dialog, document) ===
            false
          ) {
            // Errors, stop the process.
            this.spinner.hideSpinner(action);
            return;
          }

          // Update what need to be updated before saving
          document.montant = this.docService.getMontantFromDocument(document);

          // If document already exist, replace it using assign
          if (orderDocNum || document.numeroPiece) {
            const existingDocument = elevage.documents.find(
              (doc) =>
                doc.numeroPiece === orderDocNum ||
                doc.numeroPiece === document.numeroPiece
            );
            if (existingDocument) {
              Object.assign(existingDocument, document);
            } else {
              elevage.documents.push(document);
            }
          } else {
            // Document is new, add it to the elevage
            elevage.documents.push(document);
          }

          // Get the origin of the article
          const origin = await this.store$
            .select(getCurrentDocumentArticleOrigin)
            .pipe(take(1))
            .toPromise();

          // XAS-15: check the doses selected are available from Dispo Coop when ordering, ask for confirmation if not
          let confirmMessage = null;
          const articlesWithoutDosesStock: string[] = [];
          if (
            origin === ArticleOrigin.OrderEstimateDoses &&
            document &&
            document.ligne.length > 0
          ) {
            for (const line of document.ligne) {
              const hasDosesStock =
                await this.articleService.hasDosesStockFromDepotTerrain(
                  line.article.reference,
                  line.quantite
                );
              if (hasDosesStock === false) {
                articlesWithoutDosesStock.push(line.article.reference);
              }
            }
            if (articlesWithoutDosesStock.length > 0) {
              confirmMessage = this.translateService
                .instant('doses-stock-dispo-coop-warning')
                .replace('{{articles}}', articlesWithoutDosesStock.join(', '));
            }
          }

          // Get the encaissement from the store to check if he exists
          const encaissement: Encaissement = await this.store$
            .select(getCurrentEncaissement)
            .pipe(take(1))
            .toPromise();

          // Check if an encaissement exists and if he is really related to the current document
          if (encaissement && encaissement?.documents.length > 0) {
            const encaissementDocument = encaissement.documents[0];
            if (
              encaissementDocument.numXpertMobile !== document.numXpertMobile
            ) {
              this.store$.dispatch(
                setCurrentEncaissement({ encaissement: null })
              );
            }
          }

          // Generic confirm message. If set, will display a dialog to confirm the save. If not, save immediately.
          if (!confirmMessage) {
            this.actions$
              .pipe(ofType(updateItemSuccess), take(1))
              .subscribe(() => {
                if (encaissement) {
                  this.store$.dispatch(
                    saveCurrentEncaissement({ notification: false })
                  );
                }
                // Clear the document in the store
                this.store$.dispatch(
                  setCurrentDocument({ document: null, articleOrigin: null })
                );
                this.elevageService.setStoreCurrentElevage(elevage.numero);
                this.spinner.hideSpinner(action);
                this.showSaveConfirmationDialog(route);
              });

            this.store$.dispatch(
              updateItem({ storeKey: 'elevage-grid', item: elevage })
            );
          } else {
            const dialog = this.dialog.open(TcPromptDialogComponent, {
              width: '37.5em',
              data: {
                title: this.translateService.instant('prompt.title'),
                disableTextTranslation: true,
                text: confirmMessage,
                displayCancelButton: true,
                displayConfirmButton: true,
                disableClose: true,
              },
            });
            dialog.afterClosed().subscribe((result) => {
              if (!result) return;
              this.spinner.showSpinner(action);

              this.actions$
                .pipe(ofType(updateItemSuccess), take(1))
                .subscribe(() => {
                  if (encaissement) {
                    this.store$.dispatch(
                      saveCurrentEncaissement({ notification: false })
                    );
                  }

                  // Clear the document in the store
                  this.store$.dispatch(
                    setCurrentDocument({ document: null, articleOrigin: null })
                  );
                  this.elevageService.setStoreCurrentElevage(elevage.numero);
                  this.spinner.hideSpinner(action);
                  this.showSaveConfirmationDialog(route);
                });

              this.store$.dispatch(
                updateItem({ storeKey: 'elevage-grid', item: elevage })
              );
            });
            this.spinner.hideSpinner(action);
          }
        })
      ),
    { dispatch: false }
  );

  updateOrderDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateOrderDocument),
        tap(async (payload) => {
          const { storeKey } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          const headingFormValue = await selectValueByKey(
            getTcSmartFormCurrentModel,
            this.formStore$,
            storeKey
          );

          const elevage = R.clone(
            await this.store$
              .select(getCurrentElevage)
              .pipe(take(1))
              .toPromise()
          );

          const siteLivraison = headingFormValue?.adresseLivraison
            ? elevage.site?.find(
                (site) =>
                  site.numeroInterne === headingFormValue?.adresseLivraison
              )
            : undefined;

          if (document && headingFormValue) {
            // Update the fields from the form config
            document.date = headingFormValue.date;
            document.dateCommande = headingFormValue.date;
            document.dateLivraison = headingFormValue.dateLivraison;
            document.noteDeReglement = headingFormValue.noteDeReglement;
            //todo @MTU Clarify where cuve combo in the form is going. Unable to select it again and to save the data.
            document.siteLivraison = siteLivraison
              ? {
                  _id: siteLivraison.numeroInterne,
                  intitule: siteLivraison.intitule,
                }
              : null;

            const origin = await this.store$
              .select(getCurrentDocumentArticleOrigin)
              .pipe(take(1))
              .toPromise();

            this.store$.dispatch(
              setCurrentDocument({ document: document, articleOrigin: origin })
            );
          }
        })
      ),
    { dispatch: false }
  );

  updateOrderLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateOrderLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          const line = document.ligne.find(
            (item) => item.article.reference === rowData.article.reference
          );
          if (line) {
            line.quantite = rowData.quantite;

            const origin = await this.store$
              .select(getCurrentDocumentArticleOrigin)
              .pipe(take(1))
              .toPromise();

            this.store$.dispatch(
              setCurrentDocument({ document: document, articleOrigin: origin })
            );
            this.store$.dispatch(recalculatePrice());
          }
        })
      ),
    { dispatch: false }
  );

  removeOrderLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeOrderLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          document.ligne = [
            ...document.ligne.filter(
              (item) => item.article.reference !== rowData.article.reference
            ),
          ];

          const origin = await this.store$
            .select(getCurrentDocumentArticleOrigin)
            .pipe(take(1))
            .toPromise();

          this.store$.dispatch(
            setCurrentDocument({ document: document, articleOrigin: origin })
          );
          setTimeout(() => {
            this.store$.dispatch(
              deleteItemSuccess({ storeKey, item: rowData })
            );
            this.store$.dispatch(recalculatePrice());
          });
        })
      ),
    { dispatch: false }
  );

  recalculatePrice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recalculatePrice),
        tap(async () => {
          this.priceService.computePricesForDocument();
        })
      ),
    { dispatch: false }
  );

  private showSaveConfirmationDialog(route: CustomRoutes): void {
    this.dialog
      .open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant(
            'commande-detail-page.saveConfirmation'
          ),
          confirm: this.translateService.instant('globalLabels.close'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.store$.dispatch(
          navigate({
            route: `/${route}`,
            queryParams: {},
            storeKey: null,
          })
        );
      });
  }
}
